const LinkBox = (props) => {
  return (
    <div className={props.name}>
      <svg
        width="14"
        height="13"
        viewBox="0 0 14 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.177533 4.22833H3.1779V13H0.177533V4.22833ZM1.6978 0C0.6709 0 0 0.654948 0 1.51459C0 2.35655 0.651267 3.03054 1.65853 3.03054H1.67767C2.7242 3.03054 3.37593 2.35652 3.37593 1.51459C3.3563 0.654948 2.72423 0 1.6978 0ZM10.5446 4.02227C8.952 4.02227 8.2385 4.8733 7.84047 5.47012V4.22833H4.8392C4.87893 5.05121 4.8392 13 4.8392 13H7.84047V8.10128C7.84047 7.83891 7.8601 7.57752 7.93907 7.38956C8.15637 6.8658 8.65017 6.32342 9.47847 6.32342C10.5652 6.32342 10.9992 7.12817 10.9992 8.30682V13H14V7.97013C14 5.27589 12.5199 4.02227 10.5446 4.02227Z"
          fill="white"
        />
      </svg>
    </div>
  );
};
export default LinkBox;
