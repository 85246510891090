import React, { useContext } from 'react';
import { Markup } from 'interweave';
// customs
import './style.scss'
import logo from '../../assets/images/footer/innercircle-logo-lt.png'
import { IcContext } from '../../hooks';
import MobileFooter from './mobileFooter'
const CustomFooter = () => {
    const footData = useContext(IcContext)
    return (
        <>
            <div className='Ic-Footer-layout bg-primary-800'>
                <div className='IC-Footer d-flex justify-content-center'>
                    <div className='footer-copyright align-self-center justify-content-between'>
                        <div className='copyright'>
                            <img src={footData?.footer?.footer_logo ? footData?.footer?.footer_logo : logo} alt='IC_footerLogo' width={106} className='IC_footerLogo me-2' />
                            {/* <span className='f-12 text-color-10 copy-text'> <Markup content={footData?.footer?.footer_copy_right} />
                                <a className='ms-2 text-color-10 copy-text' href={footData?.footer?.footer_privacy_link}>{footData?.footer?.footer_privacy_text} </a>
                                - <a className=' text-color-10 copy-text' href={footData?.footer?.footer_terms_link}>{footData?.footer?.footer_terms_text} </a>
                            </span> */}
                            <span className="f-12 text-white copy-text">
              {" "}
              © 2022 Lighter Side of Real Estate
              <a className="ms-2 text-white copy-text" href={process.env.REACT_APP_DOMAINURL + '/privacy-policy'}>
                Privacy{" "}
              </a>
              -{" "}
              <a className=" text-white copy-text" href={process.env.REACT_APP_DOMAINURL + '/terms'}>
                Terms
              </a>
            </span>
                        </div>
                    </div>
                </div>
            </div>
            <MobileFooter />
        </>
    );
};
export default CustomFooter;