import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route, } from "react-router-dom";
import { IcContext } from './hooks'
//pages
import Layout from "./layouts";
import PatternLibrary from "./views/pages/lsPatternlibrary";

function App() {
  const [state, setState] = useState({
    bkdImg: process.env.REACT_APP_CDNURL + "uploads/2022/05/login_bg.jpeg",
    icLogo: process.env.REACT_APP_CDNURL + "uploads/logo_circle.svg"
  })
  return (
    // basename={process.env.REACT_APP_BASEPATH}
    <IcContext.Provider value={state}>
      <Router>
        <Routes>
          {/* <Route path="/" element={!isLogedIn() ? <Login /> : <Navigate replace to={"/"} />} /> */}
          <Route path="/" element={<Layout />} />
          {/* <Route path="/forgot-password" element={<Layout />} />
          <Route path="/reset-password" element={<Layout />} /> */}
          <Route path="/pattern-library" element={<PatternLibrary/>}/>
        </Routes>
      </Router>
    </IcContext.Provider>
  );
}

export default App;
