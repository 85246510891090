import axios from 'axios';
import delay from './Delay';
import Cookies from 'js-cookie'

export const isLoading = () => {
  document.getElementById("pageLoader").classList.add("d-block");
};

export const isNotLoading = () => {
  if (
    document.querySelector("#pageLoader") &&
    document.querySelector("#pageLoader").classList.contains("d-block")
  ) {
    document.querySelector("#pageLoader").classList.remove("d-block");
  }
};

export const isLogedIn = () => {
  return !!Cookies.get('sub_domain_login_ic_auth')
};


export const postFormdataMethod = async (data) => {
  isLoading()
  // let user_token = {user_token: Cookies.get('sub_domain_login_ic_auth')
  //  || Cookies.get('login_ic_auth')
  // }
  // let bodyData = {...data.body,...user_token} 
  const config = { headers: { 'content-type': 'multipart/form-data' } }
  return new Promise((resolve, reject) => {
    axios.post(process.env.REACT_APP_API + data.url, data.body, config)
      .then(res => {
        isNotLoading()
        setTimeout(() => {
          resolve(Object.assign([], res));
        }, delay)

      }).catch(error => {
        isNotLoading()
        resolve(error);
      })
  });
}

export const postMethod = async (data) => {
  const config = { headers: { 'content-type': 'multipart/form-data' } }
  return new Promise((resolve, reject) => {
    axios.post(process.env.REACT_APP_API + data.url, data.body, config)
      .then(res => {
        setTimeout(() => {
          resolve(Object.assign([], res));
        }, delay)

      }).catch(error => {
        resolve(error);
      })
  });
}