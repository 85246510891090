import React, { useEffect, useContext, useState } from 'react';
import { useLocation } from "react-router-dom";
import { Collapse, Nav, NavItem, NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { IcContext } from '../../hooks';
import { icData } from '../../constants/localApi'
import { Markup } from 'interweave';
import { DropdownSubmenu } from 'react-bootstrap-submenu';
import Avatar from '@mui/material/Avatar';
import AvatarUser from '../../assets/icons/avatar.png'
import searchIcon from '../../assets/icons/search.png'
import circleSearch from '../../assets/icons/search_icon_mobile.png'
import Button from '../../components/Form/Button'

const NavItems = (props) => {
    const [navMenus, setNavMenus] = useState(icData?.menus?.top_main_bar)
    const [state, setState] = useState({
        dropdownOpen: false,
        onScroll: ''
    })
    const [showBar, setShowBar] = useState(false)
    const [windowWidth, setWindowWidth] = useState()
    const [onSearch, setOnSearch] = useState(false)
    const [onSearchDesk, setOnSearchDesk] = useState(false)
    const Location = useLocation().pathname.slice(1);

    const navData = useContext(IcContext)
    const navbarData = navData?.menus?.top_main_bar.length > 0 ? navData?.menus?.top_main_bar : icData?.menus?.top_main_bar

    useEffect(() => {
        if (navbarData) {
            setNavMenus(navbarData)
        }
        setWindowWidth(window.innerWidth)
    }, [])

    useEffect(() => {
        if (windowWidth < 950 && Location !== 'content-calendar') {
            setOnSearchDesk(false)
            window.addEventListener("scroll", getScrollHeight);
            return () => window.removeEventListener("scroll", getScrollHeight);
        } else if (windowWidth > 950) {
            setState({ ...state, onScroll: '' })
        }

    }, [windowWidth])

    window.onresize = (event) => {
        setWindowWidth(event.target.innerWidth)
    };



    const getScrollHeight = () => {
        if (windowWidth < 950) {
            if (window.pageYOffset < 50) {
                setState({ ...state, onScroll: '' })
            } else if (window.pageYOffset > 50) {
                setState({ ...state, onScroll: 'newPosition' })
            }
        } else {
            setState({ ...state, onScroll: '' })
        }
    }

    const toggle = (id) => {
        setState(state => ({
            [id]: !state[id]
        }));
    }

    const onMouseEnter = (id) => {
        setState({ [id]: true });
    }

    const onMouseLeave = (id) => {
        setState({ [id]: false });
    }

    const onSidebarView = () => {
        setShowBar(!showBar);
    }

    // Top Search 

    const handleClose = () => {
        setOnSearch(false)
    }
    const onSearchView = () => {
        setOnSearch(true)
    }
    const onSearchDeskView = () => {
        setOnSearchDesk(true)
    }
    return (
        <>
            <Collapse navbar>
                <Nav className='ms-auto Navitems blog-navbar' navbar>
                    {navMenus?.map((x, i) =>
                    (
                        <React.Fragment key={i}>
                            {
                                x.children?.length ? <>
                                    <UncontrolledDropdown key={i} onMouseOver={() => onMouseEnter("dropdownOpen" + x.id)} onMouseLeave={() => onMouseLeave("dropdownOpen" + x.id)} isOpen={state["dropdownOpen" + x.id]} toggle={() => toggle("dropdownOpen" + x.id)} nav inNavbar>
                                        <DropdownToggle nav caret>
                                            {x.title}
                                        </DropdownToggle>
                                        <DropdownMenu end>
                                            {x.children.map((y, i) => {
                                                return (<React.Fragment key={i}>
                                                    {y.subchildren?.length ?
                                                        <DropdownSubmenu key={i} className='sub-menu' title={y.title}>
                                                            {y.subchildren?.map((z, i1) => {
                                                                return (
                                                                    // <a className='ic-anchor' style={{ color: "#444444" }} href={z.link}>
                                                                    <DropdownItem key={i1} href={z.link}>
                                                                        {z.title}
                                                                    </DropdownItem>
                                                                    // </a>
                                                                )
                                                            })}

                                                        </DropdownSubmenu>
                                                        :
                                                        //   <a className='ic-anchor' style={{ color: "#444444" }} href={y.link}>
                                                        <DropdownItem className='ic-anchor' key={i} href={y.link}>
                                                            <Markup content={y.title} />
                                                        </DropdownItem>
                                                        //   </a>
                                                    }
                                                </React.Fragment>)
                                            })}
                                        </DropdownMenu>
                                    </UncontrolledDropdown></>
                                    :
                                    <NavItem key={i}>
                                        <NavLink className='curs-point ic-anchor' href={x.link}>
                                            {x.title}
                                            {/* <a className='ic-anchor' style={{ color: "#444444" }} href="https://ic-dev.lightersideofrealestate.com/dashboard" target="_blank">{x.title}</a> */}
                                        </NavLink>
                                    </NavItem>
                            }
                        </React.Fragment>
                    )
                    )}
                </Nav>
            </Collapse>
            {Location !=='' &&
            <Nav className={`NavAvatar ${state.onScroll}`}>
                <NavItem className='align-self-center'>
                    <NavLink>
                        <p className='mb-0'><img alt='search' className='SearchIcon' src={windowWidth > 950 ? searchIcon : circleSearch} onClick={windowWidth > 950 ? onSearchDeskView : onSearchView} /></p>
                    </NavLink>
                </NavItem>
                <NavItem className='align-self-center NavBlog'>
                    <NavLink href={navData?.ic_view_myblog_link} target='_blank'>
                        <Button
                            name='View My Blog'
                            size='sm'
                            className='btn-primary text-color-10 viewBlog'
                        />
                    </NavLink>
                    {/* <a href={this.props.dealsList?.ic_view_myblog_link} target="_blank"> <Button className='Ic-info ic-header-view-btn' size='sm' >View My Blog</Button></a> */}
                </NavItem>
                <NavItem>
                    <NavLink>
                        <Avatar className='curs-point navAvatar' alt="Remy Sharp" src={navData?.ic_user_profile_pic ? navData?.ic_user_profile_pic : AvatarUser} onClick={onSidebarView} />
                    </NavLink>
                </NavItem>
            </Nav>
            }
        </>
    );
};

export default NavItems;