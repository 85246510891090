import React from 'react';
import './style.scss'
import Spin from "../../assets/icons/spinner.svg"
const index = () => {
    return (
        <div className='Ic-Loader d-flex justify-content-center'>
            {/* <div className='Background_loader'></div> */}
            {/* <div className="dot-spin"></div> */}
            <img className="centerload" src={Spin} />

        </div>
    );
};

export default index;