import React, { useState, useContext } from 'react';
import { Navbar, NavbarToggler, NavbarBrand } from 'reactstrap';
import { useLocation } from 'react-router-dom';
// customs
import './style.scss'
import logo from '../../assets/images/header/innercircle-logo.png'
import { IcContext } from '../../hooks';
//pages
import NavItems from './navItems';

const Customheader = () => {

    const Location = useLocation()
    const headData = useContext(IcContext)

    return (
        <div className='IC-Header bg-white'>
            <Navbar color="faded" light expand="md" className='IC-Navbar p-0'>
                <NavbarBrand href="/" className="me-auto">
                    <img src={headData?.header?.header_logo ? headData?.header?.header_logo : logo} alt='Ic_Logo' />
                </NavbarBrand>
                {Location !== "/login" || Location !== "/pattern-library" &&
                    <>
                        < NavItems />
                    </>
                }
            </Navbar>
        </div>
    );
};

export default Customheader;