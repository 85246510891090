import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { isLogedIn } from "../../services/api";
import { postMethod, isNotLoading, isLoading } from '../../services/api'
import Loader from '../../components/loader'
import Login from '../../views/pages/login'

const PrivateRoute = () => {
  const navigate = useNavigate();
  const searchUrl = useLocation().search
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const authkey = query.get("lik");
  const refkey = query.get("reftok");
  const loickey = query.get("loic");
  const rtnkey = query.get("rtn");
  const utmadkey = query.get("utm_ad");
  const typekey = query.get("type");
  const ic_page_slug = query.get("ic_page_slug");
  const ic_rid = query.get("rid");

    useEffect(() => {
      if (refkey || authkey !== null || loickey || rtnkey || utmadkey || typekey) {
        autoLogin();
      }
    }, [])

  const autoLogin = async () => {
    isLoading();
    const autoLogkey = authkey === null ? "" : authkey
    const obj = {
      url: "api_index.php",
      body: {
        action: "user_data_request",
        user_token: refkey,
        lik: autoLogkey,
        loic: loickey,
        rtn: rtnkey,
        utm_ad: utmadkey,
        type: typekey,
        page_url: searchUrl, // search page redirection
        rid:ic_rid, //trial 29$ popup but couldn't use currently
        ic_page_slug: ic_page_slug
      },
    };
    let { data } = await postMethod(obj);
    if (data) {
      if (data.status === 1) {
        window.location.href = data.redirect_url ? data.redirect_url : process.env.REACT_APP_DOMAINURL + '/dashboard'
        isLoading();
      } else {
        isNotLoading();
        navigate("/");
      }
    }
  }
  if (!authkey && !refkey) {
    // if (!isLogedIn()) {
    return <Login />;
    // }else{
    // return children;
    // window.location.href=process.env.REACT_APP_DOMAINURL + '/dashboard'
    // }
  }
  else {
    // isLoading();
  }
  return (
    <div id='pageLoader'><Loader /></div>
  );
};

export default PrivateRoute;