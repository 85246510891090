const InstaBox = (props) => {
  return (
    <div className={props.name}>
      <svg
        width="13"
        height="13"
        viewBox="0 0 13 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.5 3.11895C4.66532 3.11895 3.14516 4.6129 3.14516 6.47379C3.14516 8.33468 4.63911 9.82863 6.5 9.82863C8.36089 9.82863 9.85484 8.30847 9.85484 6.47379C9.85484 4.63911 8.33468 3.11895 6.5 3.11895ZM6.5 8.62298C5.32057 8.62298 4.35081 7.65323 4.35081 6.47379C4.35081 5.29435 5.32057 4.3246 6.5 4.3246C7.67944 4.3246 8.64919 5.29435 8.64919 6.47379C8.64919 7.65323 7.67944 8.62298 6.5 8.62298Z"
          fill="white"
        />
        <path
          d="M9.98589 3.8004C10.4057 3.8004 10.746 3.4601 10.746 3.04032C10.746 2.62054 10.4057 2.28024 9.98589 2.28024C9.56611 2.28024 9.22581 2.62054 9.22581 3.04032C9.22581 3.4601 9.56611 3.8004 9.98589 3.8004Z"
          fill="white"
        />
        <path
          d="M11.9516 1.0746C11.2702 0.366935 10.3004 0 9.1996 0H3.8004C1.52016 0 0 1.52016 0 3.8004V9.17339C0 10.3004 0.366935 11.2702 1.10081 11.9778C1.80847 12.6593 2.75202 13 3.82661 13H9.17339C10.3004 13 11.244 12.6331 11.9254 11.9778C12.6331 11.2964 13 10.3266 13 9.1996V3.8004C13 2.6996 12.6331 1.75605 11.9516 1.0746ZM11.8468 9.1996C11.8468 10.0121 11.5585 10.6673 11.0867 11.1129C10.6149 11.5585 9.95968 11.7944 9.17339 11.7944H3.82661C3.04032 11.7944 2.38508 11.5585 1.91331 11.1129C1.44153 10.6411 1.20565 9.98589 1.20565 9.17339V3.8004C1.20565 3.01411 1.44153 2.35887 1.91331 1.8871C2.35887 1.44153 3.04032 1.20564 3.82661 1.20564H9.22581C10.0121 1.20564 10.6673 1.44153 11.1391 1.91331C11.5847 2.38508 11.8468 3.04032 11.8468 3.8004V9.1996Z"
          fill="white"
        />
      </svg>
    </div>
  );
};
export default InstaBox;
