const InstaWhite = (props) => {
  return (
    <div className={props.name}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 4.79839C7.17742 4.79839 4.83871 7.09677 4.83871 9.95968C4.83871 12.8226 7.1371 15.121 10 15.121C12.8629 15.121 15.1613 12.7823 15.1613 9.95968C15.1613 7.1371 12.8226 4.79839 10 4.79839ZM10 13.2661C8.18548 13.2661 6.69355 11.7742 6.69355 9.95968C6.69355 8.14516 8.18548 6.65323 10 6.65323C11.8145 6.65323 13.3065 8.14516 13.3065 9.95968C13.3065 11.7742 11.8145 13.2661 10 13.2661Z"
          fill="#414141"
        />
        <path
          d="M15.3629 5.84677C16.0087 5.84677 16.5323 5.32324 16.5323 4.67742C16.5323 4.0316 16.0087 3.50806 15.3629 3.50806C14.7171 3.50806 14.1935 4.0316 14.1935 4.67742C14.1935 5.32324 14.7171 5.84677 15.3629 5.84677Z"
          fill="#414141"
        />
        <path
          d="M18.3871 1.65323C17.3387 0.564516 15.8468 0 14.1532 0H5.84677C2.33871 0 0 2.33871 0 5.84677V14.1129C0 15.8468 0.564516 17.3387 1.69355 18.4274C2.78226 19.4758 4.23387 20 5.8871 20H14.1129C15.8468 20 17.2984 19.4355 18.3468 18.4274C19.4355 17.379 20 15.8871 20 14.1532V5.84677C20 4.15323 19.4355 2.70161 18.3871 1.65323ZM18.2258 14.1532C18.2258 15.4032 17.7823 16.4113 17.0565 17.0968C16.3306 17.7823 15.3226 18.1452 14.1129 18.1452H5.8871C4.67742 18.1452 3.66935 17.7823 2.94355 17.0968C2.21774 16.371 1.85484 15.3629 1.85484 14.1129V5.84677C1.85484 4.6371 2.21774 3.62903 2.94355 2.90323C3.62903 2.21774 4.67742 1.85484 5.8871 1.85484H14.1935C15.4032 1.85484 16.4113 2.21774 17.1371 2.94355C17.8226 3.66935 18.2258 4.67742 18.2258 5.84677V14.1532Z"
          fill="#414141"
        />
      </svg>
    </div>
  );
};
export default InstaWhite;
