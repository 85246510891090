const LinkWhite = (props) => {
  return (
    <div className={props.name}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.253619 6.50512H4.53986V20H0.253619V6.50512ZM2.42543 0C0.958429 0 0 1.00761 0 2.33014C0 3.62546 0.930381 4.66237 2.36933 4.66237H2.39667C3.89171 4.66237 4.82276 3.62541 4.82276 2.33014C4.79471 1.00761 3.89176 0 2.42543 0ZM15.0638 6.18811C12.7886 6.18811 11.7693 7.49739 11.2007 8.41557V6.50512H6.91314C6.9699 7.7711 6.91314 20 6.91314 20H11.2007V12.4635C11.2007 12.0599 11.2287 11.6577 11.3415 11.3686C11.652 10.5628 12.3574 9.72833 13.5407 9.72833C15.0931 9.72833 15.7131 10.9664 15.7131 12.7797V20H20V12.2617C20 8.11675 17.8856 6.18811 15.0638 6.18811Z"
          fill="#1984BB"
        />
      </svg>
    </div>
  );
};
export default LinkWhite;
