import "./style.scss";
import Spin from "../../../assets/icons/spinner.svg"
const Button = ({ name, loading, className, type, onClick,nameStyle }) => {
  return (
    <button
      type={type}
      className={`btn f-16 ${className} ${loading ? "load-pad": ""}`}
      disabled={loading}
      onClick={onClick}
    >
      {!loading && <span className={`${nameStyle}`}>{name}</span>}
      {loading &&
      //  <i className="fa fa-spin fa-spinner"></i>
       <img className="spin" src={Spin} />
       }
    </button>
  );
};
export default Button;