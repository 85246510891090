const IconUp = () => {
  return (
    <svg
      width="13"
      height="6"
      viewBox="0 0 13 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.5 5.68248e-07L0.870835 6L12.1292 6L6.5 5.68248e-07Z"
        fill="#2C404E"
      />
    </svg>
  );
};
export default IconUp;
