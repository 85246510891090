
import "./style.scss";
import PrivateRoute from "../navigations/privateRoute";
import 'react-toastify/dist/ReactToastify.css';
//pages
import Loader from '../components/loader'

export default function Layout(props) {

  return (
    <>
      <div id='pageLoader'><Loader /></div>
        <PrivateRoute/>
    </>
  );
}