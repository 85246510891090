import { useState } from "react";
import { Form } from "react-bootstrap";
import { Row, Col } from "reactstrap";

import Input from "../../../components/Form/LoginInput";
import Button from "../../../components/Form/Button";
import { postMethod } from "../../../services/api";

const Forgot = (props) => {
  const { forgotRedirect } = props;
  const [emailvalidated, setEmailvalidated] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [errColor, setErrColor] = useState(false);
  const [state, setState] = useState({
    email: "",
    msg: "",
  });
  const [btnLoading, setBtnLoading] = useState(false);
  var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const handleInput = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const forgotSubmit = (event) => {
    event.preventDefault();
    if (state.email === "") {
      setState({ ...state, msg: "Please enter email address" });
      setErrColor(false);
    } else if (!state.email.match(mailformat)) {
      setState({ ...state, msg: "Invalid email address" });
      setErrColor(false);
    } else {
      sendMail();
    }
  };

  const sendMail = async () => {
    setBtnLoading(true);
    setEmailvalidated(false);

    const obj = {
      url: "api_functions.php",
      body: {
        action: "forgot_request",
        user_email: state.email,
      },
    };
    let { data } = await postMethod(obj);
    if (data.status === 1) {
      setState({ ...state, msg: data.msg });
      setErrColor(true);
      setBtnLoading(false);
    } else {
      setState({ ...state, msg: data.msg });
      setErrColor(false);
      setBtnLoading(false);
    }
    setErrorMessage("");
  };

  return (
    <Form
      className="login-form"
      noValidate
      validated={emailvalidated}
      onSubmit={forgotSubmit}
    >
      <Row>
        <Col lg="12">
          <Input
            required
            type="email"
            name="email"
            size="lg mb-1"
            placeholder="Email"
            className="mb-0"
            errorMessage={
              state?.email === ""
                ? "Please enter valid email"
                : "Invalid email address"
            }
            onChange={handleInput}
            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
            autoComplete="off"
          />
        </Col>
      </Row>
      <Row>
        {state.msg ?
          <Col lg="12" className="text-center error-disp">
            <p
              className={`mb-2 mt-2 f-14 lh-22 fw-700 ${errColor ? "text-success-500" : "text-attention-500"}`}
            >
              {state.msg}
            </p>
          </Col>
          : ""}
      </Row>

      <div className="text-center login-more forgot">
        <Button
          name="Send reset link"
          type="submit"
          className={`bar-radius text-white ${btnLoading ? "btn-white" : "btn-action-500"} ${state.msg ? "mt-1" : "mt-3"}`}
          loading={btnLoading}
          nameStyle="f-16 lh-24 fw-400"
        />
      </div>
      <Row>
        <Col lg="12" className="text-center">
          <div className="checkbox mt-2 mb-1 f-14">
            <a
              className="text-action-500 f-14 lh-22"
              style={{ textDecoration: "none" }}
              type="button"
              onClick={forgotRedirect}
            >
              Go back to sign in
            </a>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

export default Forgot;
