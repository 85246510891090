const TwitterBox = (props) => {
  return (
    <div className={props.name}>
      <svg
        width="15"
        height="12"
        viewBox="0 0 15 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15 1.42049C14.4486 1.66179 13.8551 1.82455 13.2324 1.89739C13.8682 1.52271 14.3557 0.92921 14.5856 0.221212C13.9909 0.568617 13.3326 0.820702 12.6314 0.956787C12.0702 0.368087 11.2703 0 10.3852 0C8.68583 0 7.30802 1.35635 7.30802 3.02952C7.30802 3.26662 7.33512 3.49773 7.3878 3.71984C4.8301 3.59335 2.56227 2.38747 1.04439 0.554528C0.77949 1.00175 0.627855 1.52211 0.627855 2.07783C0.627855 3.12874 1.17106 4.05615 1.99683 4.59929C1.4926 4.5834 1.0179 4.44702 0.602887 4.22011C0.602582 4.2327 0.602582 4.24559 0.602582 4.25848C0.602582 5.72603 1.66342 6.95019 3.07137 7.22896C2.81317 7.2979 2.54126 7.33507 2.26052 7.33507C2.06199 7.33507 1.86925 7.31618 1.68138 7.28051C2.07326 8.48399 3.20961 9.36014 4.55606 9.38442C3.50283 10.197 2.17618 10.6814 0.734121 10.6814C0.485963 10.6814 0.24085 10.667 0 10.6389C1.36228 11.4988 2.97972 12 4.71743 12C10.3782 12 13.4733 7.38392 13.4733 3.38053C13.4733 3.24924 13.4706 3.11825 13.4648 2.98816C14.0658 2.56192 14.5877 2.02808 15 1.42049Z"
          fill="white"
        />
      </svg>
    </div>
  );
};
export default TwitterBox;
