import React, { useState, useEffect, useContext } from "react";
import ShareIcon from "@mui/icons-material/Share";
import OutsideClickHandler from 'react-outside-click-handler';
import { Markup } from 'interweave';
import { IcContext } from '../../hooks';
import { icData } from '../../constants/localApi'

export default function MobileFooter(props) {
    const [open, setOpen] = useState({});
    const [show, setShow] = useState(false);
    const [footermenus, setFooterMenus] = useState([]);
    const [blog, setBlog] = useState(icData?.menus?.mobile_footer)

    const mobileData = useContext(IcContext)

    const dataArray = mobileData?.menus?.mobile_footer.length > 0 ? mobileData?.menus?.mobile_footer : icData?.menus?.mobile_footer


    // const dataArray = mobileData?.menus?.mobile_footer

    useEffect(() => {
        if (dataArray) {
            setFooterMenus(dataArray)
        }
        setBlog(mobileData?.ic_view_myblog_link)
    },[dataArray])

    let handleToggle = (id) => {
        setShow(true)
        setOpen({ ["open" + id]: !open["open" + id] });
    };

    return (
        <div className="CCMobileFooter">
            <OutsideClickHandler onOutsideClick={() => {
                handleToggle()
            }
            }>
                {/* mobile footer design starts */}
                <div className="ResponsiveFooter">
                    {footermenus.map((x,i) => 
                            <React.Fragment  key={i}>
                                {x.children?.length ?
                                    <>
                                        <div className={`curs-point ${x.title == "Share" ? "share" : "" ||
                                            x.title == "Resources" ? "resources" : "" ||
                                                x.title == "IC Engage" ? "ic_engage" : ""
                                            }`}
                                            onClick={() => handleToggle(x.id)}>
                                            <div className="_inner">
                                                <center>
                                                    {x.title == "Share" &&
                                                        <div className="_share">
                                                            <ShareIcon className="shareIcon" sx={{ fontSize: 30 }} />
                                                        </div>
                                                    }
                                                    {x.title == "Resources" &&
                                                        <div className="res_book">
                                                            <img
                                                                src= {process.env.REACT_APP_DOMAINURL + "/wp-content/themes/innercircle/images/onboarding/mobile_icon/ft_resource_icon.png"}
                                                                alt="resources"
                                                            />
                                                        </div>
                                                    }
                                                    {x.title == "IC Engage" &&
                                                        <div className="group">
                                                            <img
                                                                src={process.env.REACT_APP_DOMAINURL +"/wp-content/themes/innercircle/images/onboarding/mobile_icon/ft_icengage_icon.png"}
                                                                alt="ic_engage"
                                                            />
                                                        </div>
                                                    }
                                                    <p>{x.title}</p>
                                                </center>
                                            </div>
                                        </div>
                                        <div className={`${!show ? "noneTransition" : ''} ${open["open" + x.id] ? "forwardTransition" : ''} ${!open["open" + x.id] ? "reverseTransition" : ''} ${!open["open" + x.id] ? "noneTransition" : ''}  `}>
                                            {
                                                x.children.map((y, index) => (
                                                    <div  key={index}>
                                                        <div
                                                            className={
                                                                index === 0 && x.title == "Share" ? "boderTopColorShare sharelist" :
                                                                    index === 0 && x.title == "Resources" ? "boderTopColorResources sharelist" :
                                                                        index === 0 && x.title == "IC Engage" ? "boderTopColorIcEngage sharelist" : "sharelist"
                                                            }
                                                            key={index}
                                                        >
                                                            <b className="shareListText">
                                                                <a className="shareListText"
                                                                    href={y.link}
                                                                >
                                                                    <Markup content={y.title} />
                                                                </a>
                                                            </b>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </>
                                    : null}
                            </React.Fragment>
                       )
                    }
                    <div className="my_blog curs-point">
                        <div className="_inner">
                            <center>
                                <a href={blog} target="_blank">
                                    <div className="myBlog">
                                        <img
                                            src={process.env.REACT_APP_DOMAINURL +"/wp-content/themes/innercircle/images/onboarding/mobile_icon/ft_viewblog_icon.png"}
                                            alt="My_Blog"
                                        />
                                    </div></a>
                                <p>My Blog</p>

                            </center>
                        </div>
                    </div>
                </div>
                {/* mobile footer design ends */}
            </OutsideClickHandler >
        </div >
    );
}
