import React, { useState } from "react";
import "../../../index.scss";
import "./style.scss";
import Customheader from "../../../layouts/header/Customheader";
import CustomFooter from "../../../layouts/footer/CustomFooter";
import { Row, Col, Container, Card, CardBody, Button } from "reactstrap";
import Input from "../../../components/Form/LoginInput";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import FbWhite from "../../../components/social icons/Fbwhite";
import InstaWhite from "../../../components/social icons/Instawhite";
import TwitterWhite from "../../../components/social icons/Twitterwhite";
import LinkWhite from "../../../components/social icons/Linkedinwhite";
import PinterestWhite from "../../../components/social icons/Pinterestwhite";
import FbBox from "../../../components/social icons/Fbbox";
import InstaBox from "../../../components/social icons/Instabox";
import TwitterBox from "../../../components/social icons/Twitterbox";
import PinterestBox from "../../../components/social icons/Pinterestbox";
import LinkBox from "../../../components/social icons/Linkedinbox";
import SearchInput from "../../../components/social icons/SearchInput";
import UpArrow from "../../../components/social icons/Uparrow";
import AccData from "../../../components/accordian";
import IconUp from "../../../components/social icons/IconUp";

const PatternLibrary = () => {
  const [isList, showList] = useState(true);
  const [listItem, setItem] = useState(0);

  return (
    <div>
      <Customheader />
      <div className="pt-4 pb-4 main-body">
        <h1 className="text-primary-700 text-center">
          LS - PatternLibrary style Guide
        </h1>
        <Card className="p-4 bg-primary-100 mb-4">
          <h2>Theme color :</h2>
          <Container fluid>
            <CardBody className="bg-white p-4">
              <Row>
                <Col lg="6" className="align-self-center ">
                  <h4 className="text-primary-700 fw-700 mb-4">Primary:</h4>
                  <div className="theme-content">
                    <div class="colors">
                      <div class="bg-primary-800 color-box"></div>
                      <div className="color-title">
                        <span>#031A26</span>
                        <span>800</span>
                      </div>
                    </div>
                    <div class="colors">
                      <div class="bg-primary-700 color-box"></div>
                      <div className="color-title">
                        <span>#2C404E</span>
                        <span>700</span>
                      </div>
                    </div>
                    <div class="colors">
                      <div class="bg-primary-600 color-box"></div>
                      <div className="color-title">
                        <span>#576C80</span>
                        <span>600</span>
                      </div>
                    </div>
                    <div class="colors">
                      <div class="bg-primary-500 color-box"></div>
                      <div className="color-title">
                        <span>#849AAE</span>
                        <span>500</span>
                      </div>
                    </div>
                    <div class="colors">
                      <div class="bg-primary-400 color-box"></div>
                      <div className="color-title">
                        <span>#B2C2D0</span>
                        <span>400</span>
                      </div>
                    </div>
                    <div class="colors">
                      <div class="bg-primary-300 color-box"></div>
                      <div className="color-title">
                        <span>#CEDAE5</span>
                        <span>300</span>
                      </div>
                    </div>
                    <div class="colors">
                      <div class="bg-primary-200 color-box"></div>
                      <div className="color-title">
                        <span>#DFE7EF</span>
                        <span>200</span>
                      </div>
                    </div>
                    <div class="colors">
                      <div class="bg-primary-150 color-box"></div>
                      <div className="color-title">
                        <span>#E6EDF4</span>
                        <span>150</span>
                      </div>
                    </div>
                    <div class="colors">
                      <div class="bg-primary-100 color-box"></div>
                      <div className="color-title">
                        <span>#F0F5F9</span>
                        <span>100</span>
                      </div>
                    </div>
                    <div class="colors">
                      <div class="bg-primary-50 color-box"></div>
                      <div className="color-title">
                        <span>#F5F8FA</span>
                        <span>50</span>
                      </div>
                    </div>
                  </div>
                  <h4 className="text-primary-700 fw-700 mb-4">Action:</h4>
                  <div className="theme-content">
                    <div class="colors">
                      <div class="bg-action-700 color-box"></div>
                      <div className="color-title">
                        <span>#156CBD</span>
                        <span>700</span>
                      </div>
                    </div>
                    <div class="colors">
                      <div class="bg-action-500 color-box"></div>
                      <div className="color-title">
                        <span>#48A0F3</span>
                        <span>500</span>
                      </div>
                    </div>
                    <div class="colors">
                      <div class="bg-action-300 color-box"></div>
                      <div className="color-title">
                        <span>#A4D3FF</span>
                        <span>300</span>
                      </div>
                    </div>
                    <div class="colors">
                      <div class="bg-action-100 color-box"></div>

                      <div className="color-title">
                        <span>#DDEFFF</span>
                        <span>100</span>
                      </div>
                    </div>
                  </div>
                  <h4 className="text-primary-700 fw-700 mb-4">Attention:</h4>
                  <div className="theme-content">
                    <div class="colors">
                      <div class="bg-attention-700 color-box"></div>
                      <div className="color-title">
                        <span>#982805</span>
                        <span>700</span>
                      </div>
                    </div>
                    <div class="colors">
                      <div class="bg-attention-500 color-box"></div>
                      <div className="color-title">
                        <span>#FF6756</span>
                        <span>500</span>
                      </div>
                    </div>
                    <div class="colors">
                      <div class="bg-attention-300 color-box"></div>
                      <div className="color-title">
                        <span>#FFBCB2</span>
                        <span>300</span>
                      </div>
                    </div>
                    <div class="colors">
                      <div class="bg-attention-100 color-box"></div>
                      <div className="color-title">
                        <span>#FFE8E5</span>
                        <span>100</span>
                      </div>
                    </div>
                  </div>
                  <h4 className="text-primary-700 fw-700 mb-4">Success:</h4>
                  <div className="theme-content">
                    <div class="colors">
                      <div class="bg-success-500 color-box"></div>

                      <div className="color-title">
                        <span>#38AF00</span>
                        <span>500</span>
                      </div>
                    </div>
                    <div class="colors">
                      <div class="bg-success-100 color-box"></div>

                      <div className="color-title">
                        <span>#E7FFCF</span>
                        <span>100</span>
                      </div>
                    </div>
                  </div>
                  <h4 className="text-primary-700 fw-700 mb-4">Notice:</h4>
                  <div className="theme-content">
                    <div class="colors">
                      <div class="bg-notice-500 color-box"></div>
                      <div className="color-title">
                        <span>#FFDD6D</span>
                        <span>500</span>
                      </div>
                    </div>
                    <div class="colors">
                      <div class="bg-notice-100 color-box"></div>
                      <div className="color-title">
                        <span>#FFF5D4</span>
                        <span>100</span>
                      </div>
                    </div>
                  </div>
                  <h4 className="text-primary-700 fw-700 mb-4">ToggleOn:</h4>
                  <div class="colors">
                    <div class="bg-toggleOn color-box"></div>
                    <div className="color-title">
                      <span>#87D23A</span>
                      <span>toggleOn</span>
                    </div>
                  </div>
                </Col>
                <Col lg="6">
                  <Card className="bg-primary-100">
                    <h4 className="p-4 pb-0 fw-700">Class Names:</h4>
                    <hr className="mb-0" />
                    <CardBody>
                      <div className="fw-700 align-self-center masonry-grid ">
                        <div className="theme-title-content">
                          <h4 className="text-primary-700 fw-700 ">
                            bs-primary-800
                          </h4>
                          <br />
                          <h4 className="text-primary-700 fw-700">
                            bs-primary-700
                          </h4>
                          <br />
                          <h4 className="text-primary-700 fw-700 ">
                            bs-primary-600
                          </h4>
                          <br />
                          <h4 className="text-primary-700 fw-700 ">
                            bs-primary-500
                          </h4>
                          <br />
                          <h4 className="text-primary-700 fw-700 ">
                            bs-primary-400
                          </h4>
                          <br />
                          <h4 className="text-primary-700 fw-700 ">
                            bs-primary-300
                          </h4>
                          <br />
                          <h4 className="text-primary-700 fw-700 ">
                            bs-primary-200
                          </h4>
                          <br />
                          <h4 className="text-primary-700 fw-700 ">
                            bs-primary-150
                          </h4>
                          <h4 className="text-primary-700 fw-700 ">
                            bs-primary-100
                          </h4>
                          <br />
                          <h4 className="text-primary-700 fw-700 ">
                            bs-primary-50
                          </h4>
                        </div>
                        <br />
                        <div className="theme-title-content">
                          <h4 className="text-primary-700 fw-700 ">
                            bs-action-700
                          </h4>
                          <br />
                          <h4 className="text-primary-700 fw-700 ">
                            bs-action-500
                          </h4>
                          <br />
                          <h4 className="text-primary-700 fw-700 ">
                            bs-action-300
                          </h4>
                          <br />
                          <h4 className="text-primary-700 fw-700 ">
                            bs-action-100
                          </h4>
                        </div>
                        <div className="theme-title-content">
                          <br />
                          <h4 className="text-primary-700 fw-700 ">
                            bs-attention-700
                          </h4>
                          <br />
                          <h4 className="text-primary-700 fw-700 ">
                            bs-attention-500
                          </h4>
                          <h4 className="text-primary-700 fw-700 ">
                            bs-attention-300
                          </h4>
                          <br />
                          <h4 className="text-primary-700 fw-700 ">
                            bs-attention-100
                          </h4>
                          <br />
                        </div>
                        <div className="theme-title-content">
                          <h4 className="text-primary-700 fw-700 ">
                            bs-success-500
                          </h4>
                          <br />
                          <h4 className="text-primary-700 fw-700">
                            bs-success-100
                          </h4>
                          <br />
                        </div>
                        <div className="theme-title-content">
                          <h4 className="text-primary-700 fw-700 ">
                            bs-notice-500
                          </h4>
                          <br />
                          <h4 className="text-primary-700 fw-700 ">
                            bs-notice-100
                          </h4>
                          <br />
                        </div>
                        <div className="theme-title-content">
                          <h4 className="text-primary-700 fw-700 ">
                            bs-toggleOn
                          </h4>
                          <br />
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </CardBody>
          </Container>
        </Card>

        <Card className="p-4 bg-primary-100 mb-4">
          <h2>Header Section :</h2>
          <Container fluid>
            <CardBody className="bg-white p-4">
              <Row>
                <Col lg="6" className="align-self-center">
                  <h1 className="text-primary-700 f-38 lh-44">
                    Lighters Side H1 Title 38/44
                  </h1>
                  <h2 className="text-primary-700 f-24 lh-32">
                    Lighters Side H2 Title 24/32
                  </h2>
                  <h3 className="text-primary-700 f-20 lh-30">
                    Lighters Side H3 Title 20/30
                  </h3>
                  <h4 className="text-primary-700 f-18 lh-28">
                    Lighters Side H4 Title 16/25
                  </h4>
                  <h5 className="text-primary-700 f-16 lh-24">
                    Lighters Side H5 Title
                  </h5>
                  <h6 className="text-primary-700 f-14 lh-22">
                    Lighters Side H6 Title
                  </h6>
                </Col>
                <Col lg="6">
                  <Card className="bg-primary-100">
                    <h4 className="p-4 pb-0 fw-700">HTML Code:</h4>
                    <hr className="mb-0" />
                    <CardBody>
                      <div className="fw-700 align-self-center">
                        <span>
                          <span>&lt;</span>h1 className='text-primary-700 f-38
                          lh-44'<span>&gt;</span>
                        </span>
                        Lighters Side H1 Title
                        <span>
                          <span>&lt;</span>/h1<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>h2 className='text-primary-700 f-24
                          lh-32'<span>&gt;</span>
                        </span>
                        Lighters Side H2 Title
                        <span>
                          <span>&lt;</span>/h2<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>h3 className='text-primary-700 f-20
                          lh-30'<span>&gt;</span>
                        </span>
                        Lighters Side H3 Title
                        <span>
                          <span>&lt;</span>/h3<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>h4 className='text-primary-700 f-18
                          lh-28'<span>&gt;</span>
                        </span>
                        Lighters Side H4 Title
                        <span>
                          <span>&lt;</span>/h4<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>h5 className='text-primary-700 f-16
                          lh-24'<span>&gt;</span>
                        </span>
                        Lighters Side H5 Title
                        <span>
                          <span>&lt;</span>/h5<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>h6 className='text-primary-700 f-14
                          lh-22'<span>&gt;</span>
                        </span>
                        Lighters Side H6 Title
                        <span>
                          <span>&lt;</span>/h6<span>&gt;</span>
                        </span>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </CardBody>
          </Container>
        </Card>

        <Card className="p-4 bg-primary-100 mb-4">
          <h2>Button styles :</h2>
          <Container fluid>
            <CardBody className="bg-white p-4">
              <Row>
                <Col lg="6" className="">
                  <div>
                    <h4 className="text-primary-700 fw-700 mb-4">
                      Large Buttons:
                    </h4>
                    <Button
                      className="btn-primary-500 text-white mr-3"
                      size="lg"
                    >
                      Click Me
                    </Button>
                    <Button
                      className="btn-action-500 text-white mr-3"
                      size="lg"
                    >
                      Click Me
                    </Button>
                    <Button
                      className="btn-attention-500 text-white mr-3"
                      size="lg"
                    >
                      Click Me
                    </Button>
                    <Button
                      className="btn-success-500 text-white mr-3"
                      size="lg"
                    >
                      Click Me
                    </Button>
                    <Button
                      className="btn-notice-500 text-white mr-3"
                      size="lg"
                    >
                      Click Me
                    </Button>
                    <Button className="btn-toggleOn text-white mr-3" size="lg">
                      Click Me
                    </Button>
                  </div>
                  <div className="mt-4">
                    <h4 className="text-primary-700 fw-700 mb-4">
                      Medium Buttons:
                    </h4>
                    <Button
                      className="btn-primary-500 text-white mr-3"
                      size="md"
                    >
                      Click Me
                    </Button>
                    <Button
                      className="btn-action-500 text-white mr-3"
                      size="md"
                    >
                      Click Me
                    </Button>
                    <Button
                      className="btn-attention-500 text-white mr-3"
                      size="md"
                    >
                      Click Me
                    </Button>
                    <Button
                      className="btn-success-500 text-white mr-3"
                      size="md"
                    >
                      Click Me
                    </Button>
                    <Button
                      className="btn-notice-500 text-white mr-3"
                      size="md"
                    >
                      Click Me
                    </Button>
                    <Button className="btn-toggleOn text-white mr-3" size="md">
                      Click Me
                    </Button>
                  </div>
                  <div className="mt-4">
                    <h4 className="text-primary-700 fw-700 mb-4">
                      Small Buttons:
                    </h4>
                    <Button
                      className="btn-primary-500 text-white mr-3"
                      size="sm"
                    >
                      Click Me
                    </Button>
                    <Button
                      className="btn-action-500 text-white mr-3"
                      size="sm"
                    >
                      Click Me
                    </Button>
                    <Button
                      className="btn-attention-500 text-white mr-3"
                      size="sm"
                    >
                      Click Me
                    </Button>
                    <Button
                      className="btn-success-500 text-white mr-3"
                      size="sm"
                    >
                      Click Me
                    </Button>
                    <Button
                      className="btn-notice-500 text-white mr-3"
                      size="sm"
                    >
                      Click Me
                    </Button>
                    <Button className="btn-toggleOn text-white mr-3" size="sm">
                      Click Me
                    </Button>
                  </div>
                </Col>
                <Col lg="6">
                  <Card className="bg-primary-100">
                    <h4 className="p-4 pb-0 fw-700">HTML Code:</h4>
                    <hr className="mb-0" />
                    <CardBody>
                      <div className="fw-700 align-self-center">
                        <h4 className="text-primary-700">Size Large:</h4>
                        <span>
                          <span>&lt;</span>Button className='btn-primary-500
                          text-white' size="lg"<span>&gt;</span>
                        </span>
                        Click Me
                        <span>
                          <span>&lt;</span>/Button<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>Button className='btn-action-500
                          text-white' size="lg"<span>&gt;</span>
                        </span>
                        Click Me
                        <span>
                          <span>&lt;</span>/Button<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>Button className='btn-attention-500
                          text-white' size="lg"<span>&gt;</span>
                        </span>
                        Click Me
                        <span>
                          <span>&lt;</span>/Button<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>Button className='btn-success-500
                          text-white' size="lg"<span>&gt;</span>
                        </span>
                        Click Me
                        <span>
                          <span>&lt;</span>/Button<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>Button className='btn-notice-500
                          text-white' size="lg"<span>&gt;</span>
                        </span>
                        Click Me
                        <span>
                          <span>&lt;</span>/Button<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>Button className='btn-toggleOn
                          text-white' size="lg"<span>&gt;</span>
                        </span>
                        Click Me
                        <span>
                          <span>&lt;</span>/Button<span>&gt;</span>
                        </span>
                      </div>

                      <div className="fw-700 align-self-center mt-4">
                        <h4 className="text-primary-700">Size Medium:</h4>
                        <span>
                          <span>&lt;</span>Button className='btn-primary-500
                          text-white' size="md"<span>&gt;</span>
                        </span>
                        Click Me
                        <span>
                          <span>&lt;</span>/Button<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>Button className='btn-action-500
                          text-white' size="md"<span>&gt;</span>
                        </span>
                        Click Me
                        <span>
                          <span>&lt;</span>/Button<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>Button className='btn-attention-500
                          text-white' size="md"<span>&gt;</span>
                        </span>
                        Click Me
                        <span>
                          <span>&lt;</span>/Button<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>Button className='btn-success-500
                          text-white' size="md"<span>&gt;</span>
                        </span>
                        Click Me
                        <span>
                          <span>&lt;</span>/Button<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>Button className='btn-notice-500
                          text-white' size="md"<span>&gt;</span>
                        </span>
                        Click Me
                        <span>
                          <span>&lt;</span>/Button<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>Button className='btn-toggleOn
                          text-white' size="md"<span>&gt;</span>
                        </span>
                        Click Me
                        <span>
                          <span>&lt;</span>/Button<span>&gt;</span>
                        </span>
                      </div>

                      <div className="fw-700 align-self-center mt-4">
                        <h4 className="text-primary-700">Size Small:</h4>
                        <span>
                          <span>&lt;</span>Button className='btn-primary-500
                          text-white' size="sm"<span>&gt;</span>
                        </span>
                        Click Me
                        <span>
                          <span>&lt;</span>/Button<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>Button className='btn-action-500
                          text-white' size="sm"<span>&gt;</span>
                        </span>
                        Click Me
                        <span>
                          <span>&lt;</span>/Button<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>Button className='btn-attention-500
                          text-white' size="sm"<span>&gt;</span>
                        </span>
                        Click Me
                        <span>
                          <span>&lt;</span>/Button<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>Button className='btn-success-500
                          text-white' size="sm"<span>&gt;</span>
                        </span>
                        Click Me
                        <span>
                          <span>&lt;</span>/Button<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>Button className='btn-notice-500
                          text-white' size="sm"<span>&gt;</span>
                        </span>
                        Click Me
                        <span>
                          <span>&lt;</span>/Button<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>Button className='btn-toggleOn
                          text-white' size="sm"<span>&gt;</span>
                        </span>
                        Click Me
                        <span>
                          <span>&lt;</span>/Button<span>&gt;</span>
                        </span>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </CardBody>
          </Container>
        </Card>

        <Card className="p-4 bg-primary-100 mb-4">
          <h2>Input styles :</h2>
          <Container fluid>
            <CardBody className="bg-white p-4">
              <Row>
                <Col lg="6">
                  <div>
                    <h4 className="text-primary-700 fw-700 mb-4">Active :</h4>
                    <div>
                      <h5 class="Bodylarge fw-700">User name:</h5>
                      <Input
                        size="lg"
                        placeholder="Enter username"
                        className="mb-0"
                      />
                    </div>
                  </div>
                  <div className="mt-4">
                    <h4 className="text-primary-700 fw-700 mb-4">Error:</h4>

                    <div className="input-error">
                      <h5 class="Bodylarge fw-700">User name:</h5>
                      <Input
                        size="lg"
                        placeholder="Enter username"
                        className="mb-0"
                        name="error"
                      />
                    </div>
                  </div>
                  <div className="mt-4">
                    <h4 className="text-primary-700 fw-700 mb-4">
                      Invalid/Disabled:
                    </h4>
                    <div className="input-disabled">
                      <h5 class="Bodylarge fw-700">User name:</h5>

                      <Input
                        size="lg"
                        placeholder="Enter username"
                        className="mb-0 invalid"
                        name="invalid"
                      />
                    </div>
                  </div>
                </Col>
                <Col lg="6">
                  <Card className="bg-primary-100">
                    <h4 className="p-4 pb-0 fw-700">HTML Code:</h4>
                    <hr className="mb-0" />
                    <CardBody>
                      <div className="fw-700 align-self-center">
                        <h4 className="text-primary-700">Active :</h4>
                        <span>
                          <span>&lt;</span>div <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>h5 class="Bodylarge fw-700"
                          <span>&gt;</span>
                        </span>
                        User name:
                        <span>
                          <span>&lt;</span>/h5<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>input className="mb-0"
                          placeholder="Enter username" size="lg"/
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>/div<span>&gt;</span>
                        </span>
                        <br />
                        <div className="fw-700 align-self-center mt-4">
                          <h4 className="text-primary-700">Error:</h4>
                          <span>
                            <span>&lt;</span>div className="input-error"
                            <span>&gt;</span>
                          </span>
                          <br />
                          <span>
                            <span>&lt;</span>h5 class="Bodylarge fw-700"
                            <span>&gt;</span>
                          </span>
                          User name:
                          <span>
                            <span>&lt;</span>/h5<span>&gt;</span>
                          </span>
                          <br />
                          <span>
                            <span>&lt;</span>input className="mb-0" name="error"
                            placeholder="Enter username" size="lg"/
                            <span>&gt;</span>
                          </span>
                          <br />
                          <span>
                            <span>&lt;</span>/div<span>&gt;</span>
                          </span>
                        </div>
                        <div className="fw-700 align-self-center mt-4">
                          <h4 className="text-primary-700">
                            Invalid/Disabled:
                          </h4>
                          <br />
                          <span>
                            <span>&lt;</span>div className="input-disabled"
                            <span>&gt;</span>
                          </span>
                          <br />
                          <span>
                            <span>&lt;</span>h5 class="Bodylarge fw-700"
                            <span>&gt;</span>
                          </span>
                          User name:
                          <span>
                            <span>&lt;</span>/h5<span>&gt;</span>
                          </span>
                          <br />
                          <span>
                            <span>&lt;</span>input className="mb-0"
                            name="invalid" placeholder="Enter username"
                            size="lg"/
                            <span>&gt;</span>
                          </span>
                          <br />
                          <span>
                            <span>&lt;</span>/div<span>&gt;</span>
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </CardBody>
          </Container>
        </Card>

        <Card className="p-4 bg-primary-100 mb-4">
          <h2>Radio Button :</h2>
          <Container fluid>
            <CardBody className="bg-white p-4">
              <Row>
                <Col lg="6" className="">
                  <div>
                    <div>
                      <input type="radio" className="mr-2" />
                      <span> All Content </span>
                    </div>
                  </div>
                  <div className="mt-4">
                    <input type="radio" className="mr-2" />
                    <span>Articles(1356)</span>
                  </div>
                </Col>
                <Col lg="6">
                  <Card className="bg-primary-100">
                    <h4 className="p-4 pb-0 fw-700">HTML Code:</h4>
                    <hr className="mb-0" />
                    <CardBody>
                      <div className="fw-700 align-self-center">
                        <span>
                          <span>&lt;</span>div<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>input type="radio" className="mr-2"
                          checked=true/
                          <span>&gt;</span>
                          <br />
                          <span>
                            <span>&lt;</span>span
                            <span>&gt;</span>
                          </span>
                          <br />
                          All Content
                          <br />
                          <span>
                            <span>&lt;</span>/span<span>&gt;</span>
                          </span>
                          <br />
                        </span>
                        <span>
                          <span>&lt;</span>/div<span>&gt;</span>
                        </span>

                        <br />
                        <span>
                          <span>&lt;</span>div<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>input type="radio" className="mr-2" /
                          <span>&gt;</span>
                          <br />
                          <span>
                            <span>&lt;</span>span
                            <span>&gt;</span>
                          </span>
                          <br />
                          Articles(1356)
                          <br />
                          <span>
                            <span>&lt;</span>/span<span>&gt;</span>
                          </span>
                          <br />
                        </span>
                        <span>
                          <span>&lt;</span>/div<span>&gt;</span>
                        </span>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </CardBody>
          </Container>
        </Card>

        <Card className="p-4 bg-primary-100 mb-4">
          <h2>Checkbox :</h2>
          <Container fluid>
            <CardBody className="bg-white p-4">
              <Row>
                <Col lg="6" className="check-box">
                  <div>
                    <label class="container mr-4">
                      January
                      <input type="checkbox" />
                      <span class="checkmark"></span>
                    </label>
                    <label class="container">
                      July
                      <input type="checkbox" />
                      <span class="checkmark"></span>
                    </label>
                  </div>
                </Col>
                <Col lg="6">
                  <Card className="bg-primary-100">
                    <h4 className="p-4 pb-0 fw-700">HTML Code:</h4>
                    <hr className="mb-0" />
                    <CardBody>
                      <div className="fw-700 align-self-center">
                        <span>
                          <span>&lt;</span>div<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>input type="checkbox"
                          className="mr-2" checked=true/
                          <span>&gt;</span>
                          <br />
                          <span>
                            <span>&lt;</span>span
                            <span>&gt;</span>
                          </span>
                          <br />
                        </span>
                        January
                        <br />
                        <span>
                          <span>&lt;</span>/span<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>/div<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>div className="ml-4"<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>input type="checkbox"
                          className="mr-2" /<span>&gt;</span>
                          <br />
                          <span>
                            <span>&lt;</span>span
                            <span>&gt;</span>
                          </span>
                          <br />
                        </span>
                        July
                        <br />
                        <span>
                          <span>&lt;</span>/span<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>/div<span>&gt;</span>
                        </span>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </CardBody>
          </Container>
        </Card>

        <Card className="p-4 bg-primary-100 mb-4">
          <h2>Social Links Icons :</h2>
          <Container fluid>
            <CardBody className="bg-white p-4">
              <Row>
                <Col lg="6" className="">
                  <div>
                    <h4 className="text-primary-700 fw-700 mb-4">
                      White Background :
                    </h4>
                    <div className="social-icons">
                      <div className="sl-icon sl-icon-w">
                        <FbWhite name="sl-white fb-w" />
                        <span> Facebook</span>
                      </div>
                      <div className="sl-icon sl-icon-w">
                        <TwitterWhite name="sl-white twitter-w" /> Twitter
                      </div>
                      <div className="sl-icon sl-icon-w">
                        <InstaWhite name="sl-white insta-w" />
                        Instagram
                      </div>
                      <div className="sl-icon sl-icon-w">
                        <LinkWhite name="sl-white link-w" /> LinkedIn
                      </div>
                      <div className="sl-icon sl-icon-w">
                        <PinterestWhite name="sl-white pin-w" /> Pinterest
                      </div>
                    </div>
                    <h4 className="text-primary-700 fw-700 mb-4">
                      Rectangle Border :
                    </h4>

                    <div className="social-icons ">
                      <div className="sl-icon">
                        <FbBox name="sl-color sl-box fb-box" />
                      </div>
                      <div className="sl-icon">
                        <TwitterBox name="sl-color sl-box twitter-box" />{" "}
                      </div>
                      <div className="sl-icon">
                        <InstaBox name="sl-color sl-box insta-box" />
                      </div>
                      <div className="sl-icon">
                        <LinkBox name="sl-color sl-box link-box" />
                      </div>
                      <div className="sl-icon">
                        <PinterestBox name="sl-color sl-box pin-box" />{" "}
                      </div>
                    </div>
                    <h4 className="text-primary-700 fw-700 mb-4">
                      Circular Border :
                    </h4>

                    <div className="social-icons ">
                      <div className="sl-icon sl-round">
                        <FbBox name="sl-color fb-round" />
                      </div>
                      <div className="sl-icon sl-round">
                        <TwitterBox name="sl-color twitter-round" />
                      </div>
                      <div className="sl-icon sl-round">
                        <InstaBox name="sl-color insta-round" />
                      </div>
                      <div className="sl-icon sl-round">
                        <LinkBox name="sl-color link-round" />
                      </div>
                      <div className="sl-icon sl-round">
                        <PinterestBox name="sl-color pin-round" />
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg="6">
                  <Card className="bg-primary-100">
                    <h4 className="p-4 pb-0 fw-700">HTML Code:</h4>
                    <hr className="mb-0" />
                    <CardBody>
                      <div className="fw-700 align-self-center mt-4">
                        <h4 className="text-primary-700 fw-700 mb-4">
                          White Background :
                        </h4>
                        <br />
                        <span>
                          <span>&lt;</span>div className="social-icons"
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>div className="sl-icon sl-icon-w"
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>div className="sl-white fb-w"
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>svg width="10" height="22
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>/svg<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>span <span>&gt;</span>
                        </span>
                        <br />
                        Facebook
                        <br />
                        <span>
                          <span>&lt;</span>/span<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>/div<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>/div<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>div className="sl-icon sl-icon-w"
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>div className="sl-white fb-w"
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>svg width="10" height="22
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>/svg<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>span <span>&gt;</span>
                        </span>
                        <br />
                        Twitter
                        <br />
                        <span>
                          <span>&lt;</span>/span<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>/div<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>/div<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>div className="sl-icon sl-icon-w"
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>div className="sl-white fb-w"
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>svg width="10" height="22
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>/svg<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>span <span>&gt;</span>
                        </span>
                        <br />
                        Instagram
                        <br />
                        <span>
                          <span>&lt;</span>/span<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>/div<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>/div<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>div className="sl-icon sl-icon-w"
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>div className="sl-white fb-w"
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>svg width="10" height="22
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>/svg<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>span <span>&gt;</span>
                        </span>
                        <br />
                        LinkedIn
                        <br />
                        <span>
                          <span>&lt;</span>/span<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>/div<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>/div<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>div className="sl-icon sl-icon-w"
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>div className="sl-white fb-w"
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>svg width="10" height="22
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>/svg<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>span <span>&gt;</span>
                        </span>
                        <br />
                        Pinterest
                        <br />
                        <span>
                          <span>&lt;</span>/span<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>/div<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>/div<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>/div<span>&gt;</span>
                        </span>
                        <br />
                        <br />
                        <h4 className="text-primary-700 fw-700 mb-4">
                          Rectangle Border :
                        </h4>
                        <br />
                        <span>
                          <span>&lt;</span>div className="social-icons"
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>div className="sl-icon "
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>div className="sl-color sl-box
                          fb-box"
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>svg width="10" height="22
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>/svg<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>span <span>&gt;</span>
                        </span>
                        <br />
                        Facebook
                        <br />
                        <span>
                          <span>&lt;</span>/span<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>/div<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>/div<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>div className="sl-icon"
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>div className="sl-color sl-box
                          twitter-box"
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>svg width="10" height="22
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>/svg<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>span <span>&gt;</span>
                        </span>
                        <br />
                        Twitter
                        <br />
                        <span>
                          <span>&lt;</span>/span<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>/div<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>/div<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>div className="sl-icon "
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>div className="sl-color sl-box
                          insta-box"
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>svg width="10" height="22
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>/svg<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>span <span>&gt;</span>
                        </span>
                        <br />
                        Instagram
                        <br />
                        <span>
                          <span>&lt;</span>/span<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>/div<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>/div<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>div className="sl-icon "
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>div className="sl-color sl-box
                          link-box"
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>svg width="10" height="22
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>/svg<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>span <span>&gt;</span>
                        </span>
                        <br />
                        LinkedIn
                        <br />
                        <span>
                          <span>&lt;</span>/span<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>/div<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>/div<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>div className="sl-icon "
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>div className="sl-color sl-box
                          pin-box"
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>svg width="10" height="22
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>/svg<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>span <span>&gt;</span>
                        </span>
                        <br />
                        Pinterest
                        <br />
                        <span>
                          <span>&lt;</span>/span<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>/div<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>/div<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>/div<span>&gt;</span>
                        </span>
                        <br />
                        <br />
                        <h4 className="text-primary-700 fw-700 mb-4">
                          Circular Border :
                        </h4>
                        <br />
                        <br />
                        <span>
                          <span>&lt;</span>div className="social-icons"
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>div className="sl-icon sl-round"
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>div className="sl-color fb-round"
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>svg width="10" height="22
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>/svg<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>span <span>&gt;</span>
                        </span>
                        <br />
                        Facebook
                        <br />
                        <span>
                          <span>&lt;</span>/span<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>/div<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>/div<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>div className="sl-icon sl-round"
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>div className="sl-color
                          twitter-round"
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>svg width="10" height="22
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>/svg<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>span <span>&gt;</span>
                        </span>
                        <br />
                        Twitter
                        <br />
                        <span>
                          <span>&lt;</span>/span<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>/div<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>/div<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>div className="sl-icon sl-round"
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>div className="sl-color insta-round"
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>svg width="10" height="22
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>/svg<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>span <span>&gt;</span>
                        </span>
                        <br />
                        Instagram
                        <br />
                        <span>
                          <span>&lt;</span>/span<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>/div<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>/div<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>div className="sl-icon sl-round"
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>div className="sl-color link-round"
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>svg width="10" height="22
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>/svg<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>span <span>&gt;</span>
                        </span>
                        <br />
                        LinkedIn
                        <br />
                        <span>
                          <span>&lt;</span>/span<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>/div<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>/div<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>div className="sl-icon sl-round"
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>div className="sl-color pin-round
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>svg width="10" height="22
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>/svg<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>span <span>&gt;</span>
                        </span>
                        <br />
                        Pinterest
                        <br />
                        <span>
                          <span>&lt;</span>/span<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>/div<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>/div<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>/div<span>&gt;</span>
                        </span>
                        <br />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </CardBody>
          </Container>
        </Card>

        <Card className="p-4 bg-primary-100 mb-4">
          <h2>On/Off Switch :</h2>
          <Container fluid>
            <CardBody className="bg-white p-4">
              <Row>
                <Col lg="6" className="">
                  <div>
                    {/* <FontAwesomeIcon icon={faCoffee} /> */}
                    <div className="boot-switch">
                      <BootstrapSwitchButton
                        onlabel="ON"
                        onstyle="success"
                        size="lg"
                        offlabel="OFF"
                        checked={true}
                        // style='w-200 mx-3'
                      />
                      <span>General Annoncement Emails</span>
                    </div>
                    <div className="boot-switch">
                      <BootstrapSwitchButton
                        onlabel="ON"
                        onstyle="success"
                        size="lg"
                        offlabel="OFF"
                        checked={false}
                      />
                      <span>Weekly roundup Emails</span>
                    </div>
                    <div className="boot-switch">
                      <BootstrapSwitchButton
                        onlabel="ON"
                        onstyle="success"
                        size="lg"
                        offlabel="OFF"
                        checked={false}
                      />
                      <span>30-day onboarding Sequence</span>
                    </div>
                  </div>
                </Col>
                <Col lg="6">
                  <Card className="bg-primary-100">
                    <h4 className="p-4 pb-0 fw-700">HTML Code:</h4>
                    <hr className="mb-0" />
                    <CardBody>
                      <div className="fw-700 align-self-center mt-4">
                        <h4 className="text-primary-700 fw-700 mb-4">
                          What is your Message about :
                        </h4>
                        <br />
                        <span>
                          <span>&lt;</span>div
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>div class="boot-switch"
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>div class="switch btn on btn-success
                          btn-lg"
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>div class="switch-group"
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>span class="switch-on btn btn-success
                          btn-lg"
                          <span>&gt;</span>
                        </span>
                        <br />
                        ON <br />
                        <span>
                          <span>&lt;</span>/span<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>span class="switch-on btn btn-success
                          btn-lg"
                          <span>&gt;</span>
                        </span>
                        <br />
                        OFF
                        <br />
                        <span>
                          <span>&lt;</span>/span<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>span class="switch-on btn btn-success
                          btn-lg"
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>/span<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>/div<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>/div<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>span
                          <span>&gt;</span>
                        </span>
                        <br />
                        General Annoncement Emails
                        <br />
                        <span>
                          <span>&lt;</span>/span<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>/div<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>div class="boot-switch"
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>div class="switch btn on btn-success
                          btn-lg"
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>div class="switch-group"
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>span class="switch-on btn btn-success
                          btn-lg"
                          <span>&gt;</span>
                        </span>
                        <br />
                        ON <br />
                        <span>
                          <span>&lt;</span>/span<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>span class="switch-on btn btn-success
                          btn-lg"
                          <span>&gt;</span>
                        </span>
                        <br />
                        OFF
                        <br />
                        <span>
                          <span>&lt;</span>/span<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>span class="switch-on btn btn-success
                          btn-lg"
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>/span<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>/div<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>/div<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>span
                          <span>&gt;</span>
                        </span>
                        <br />
                        Weekly roundup Emails
                        <br />
                        <span>
                          <span>&lt;</span>/span<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>/div<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>div class="boot-switch"
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>div class="switch btn on btn-success
                          btn-lg"
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>div class="switch-group"
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>span class="switch-on btn btn-success
                          btn-lg"
                          <span>&gt;</span>
                        </span>
                        <br />
                        ON <br />
                        <span>
                          <span>&lt;</span>/span<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>span class="switch-on btn btn-success
                          btn-lg"
                          <span>&gt;</span>
                        </span>
                        <br />
                        OFF
                        <br />
                        <span>
                          <span>&lt;</span>/span<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>span class="switch-on btn btn-success
                          btn-lg"
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>/span<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>/div<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>/div<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>span
                          <span>&gt;</span>
                        </span>
                        <br />
                        30-day onboarding Sequence
                        <br />
                        <span>
                          <span>&lt;</span>/span<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>/div<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>/div<span>&gt;</span>
                        </span>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </CardBody>
          </Container>
        </Card>
        <Card className="p-4 bg-primary-100 mb-4">
          <h2>Drop Down :</h2>
          <Container fluid>
            <CardBody className="bg-white p-4">
              <Row>
                <Col lg="6" className="">
                  <div className="rows-content bg-primary-100">
                    <h4 className="text-primary-700 fw-700 mb-4">
                      What is your Message about?
                    </h4>
                    <div
                      className="drop-container"
                      onClick={() => showList(!isList)}
                    >
                      <div className="drop-input">
                        <span>General Suggestion</span>
                        <div className={`${isList ? "downIcon" : "upIcon"}`}>
                          {" "}
                          <IconUp />
                        </div>
                      </div>
                      <div
                        className="drop-menu"
                        style={{ border: isList ? "1px solid #ccc" : "none" }}
                      >
                        {isList &&
                          [1, 2, 3, 4, 5].map((item) => (
                            <div
                              key={item}
                              // onClick={() => props.setSize(item)}
                              className="drop-item"
                            >
                              {item}
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg="6">
                  <Card className="bg-primary-100">
                    <h4 className="p-4 pb-0 fw-700">HTML Code:</h4>
                    <hr className="mb-0" />
                    <CardBody>
                      <div>
                        <span>
                          <span>&lt;</span>div className="drop-container"
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>span
                          <span>&gt;</span>
                        </span>
                        <br />
                        General Suggestion
                        <br />
                        <span>
                          <span>&lt;</span>/span<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>div className='downIcon'
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>svg
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>/svg<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>/div<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>/div<span>&gt;</span>
                        </span>
                        <br />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </CardBody>
          </Container>
        </Card>
        <Card className="p-4 bg-primary-100 mb-4">
          <h2>Search Box :</h2>
          <Container fluid>
            <CardBody className="bg-white p-4">
              <Row>
                <Col lg="6" className="">
                  <div className="bg-primary-700 search-content">
                    <div className="search-content-div">
                      {" "}
                      <Input
                        size="lg"
                        placeholder="Search Memes, articles, postcards and covers"
                        className="mb-0 search-input"
                        name="search-input"
                      />
                      <SearchInput name="search-icon" />
                    </div>
                  </div>
                </Col>
                <Col lg="6">
                  <Card className="bg-primary-100">
                    <h4 className="p-4 pb-0 fw-700">HTML Code:</h4>
                    <hr className="mb-0" />
                    <CardBody>
                      <div>
                        <span>
                          <span>&lt;</span>div <span>&gt;</span>
                        </span>
                        <br />

                        <span>
                          <span>&lt;</span>input className="mb-0"
                          placeholder="Search Memes, articles, postcards and
                          covers" size="lg"/
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>svg
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>/svg<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>/div<span>&gt;</span>
                        </span>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </CardBody>
          </Container>
        </Card>
        <Card className="p-4 bg-primary-100 mb-4">
          <h2>Masonry grid Layout :</h2>
          <Container fluid>
            <CardBody className="bg-white p-4">
              <Row>
                <Col lg="6" className="">
                  <div>
                    <h4 className="text-primary-700 fw-700 mb-4">
                      Masonry Grid Layout using CSS :
                    </h4>
                    <div class="masonry-grid">
                      <div class="grid__item">
                        <img src="http://placeimg.com/350/480/any" />
                      </div>
                      <div class="grid__item">
                        <img src="http://placeimg.com/640/480/any" />
                      </div>
                      <div class="grid__item">
                        <img src="http://placeimg.com/300/600/any" />
                      </div>
                      <div class="grid__item">
                        <img src="http://placeimg.com/640/480/any" />
                      </div>
                      <div class="grid__item">
                        <img src="http://placeimg.com/500/250/any" />
                      </div>
                      <div class="grid__item">
                        <img src="http://placeimg.com/320/240/any" />
                      </div>
                      <div class="grid__item">
                        <img src="http://placeimg.com/480/640/any" />
                      </div>
                      <div class="grid__item">
                        <img src="http://placeimg.com/650/480/any" />
                      </div>
                      <div class="grid__item">
                        <img src="http://placeimg.com/300/500/any" />
                      </div>
                      <div class="grid__item">
                        <img src="http://placeimg.com/520/250/any" />
                      </div>
                      <div class="grid__item">
                        <img src="http://placeimg.com/640/480/any" />
                      </div>
                      <div class="grid__item">
                        <img src="http://placeimg.com/850/400/any" />
                      </div>
                      <div class="grid__item">
                        <img src="http://placeimg.com/350/350/any" />
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg="6">
                  <Card className="bg-primary-100">
                    <h4 className="p-4 pb-0 fw-700">HTML Code:</h4>
                    <hr className="mb-0" />
                    <CardBody>
                      <div className="fw-700 align-self-center">
                        <h4 className="text-primary-700 fw-700 mb-4">
                          Masonry Grid Layout using CSS :
                        </h4>
                        <br />
                        <span>
                          <span>&lt;</span>div class="masonry-grid"{" "}
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>div class="grid__item"{" "}
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>img
                          src="http://placeimg.com/350/480/any" /
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>/div<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>div class="grid__item"{" "}
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>img
                          src="http://placeimg.com/300/600/any" /
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>/div<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>div class="grid__item"{" "}
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>img
                          src="http://placeimg.com/350/350/any" /
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>/div<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>div class="grid__item"{" "}
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>img
                          src="http://placeimg.com/850/400/any" /
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>/div<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>div class="grid__item"{" "}
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>img
                          src="http://placeimg.com/640/480/any" /
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>/div<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>div class="grid__item"{" "}
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>img
                          src="http://placeimg.com/520/250/any" /
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>/div<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>div class="grid__item"{" "}
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>img
                          src="http://placeimg.com/300/500/any" /
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>/div<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>div class="grid__item"{" "}
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>img
                          src="http://placeimg.com/650/480/any" /
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>/div<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>div class="grid__item"{" "}
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>img
                          src="http://placeimg.com/480/640/any" /
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>/div<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>div class="grid__item"{" "}
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>img
                          src="http://placeimg.com/320/240/any" /
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>/div<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>div class="grid__item"{" "}
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>img
                          src="http://placeimg.com/500/250/any" /
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>/div<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>div class="grid__item"{" "}
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>img
                          src="http://placeimg.com/640/480/any" /
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>/div<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>div class="grid__item"{" "}
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>img
                          src="http://placeimg.com/300/600/any" /
                          <span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>/div<span>&gt;</span>
                        </span>
                        <br />
                        <span>
                          <span>&lt;</span>/div<span>&gt;</span>
                        </span>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </CardBody>
          </Container>
        </Card>
        <Card className="p-4 bg-primary-100 mb-4">
          <h2>Toggle Section:</h2>
          <Container fluid>
            <CardBody className="bg-white p-4">
              <Row>
                <Col lg="6" className="">
                  <div>
                    <AccData
                      header="Section 1"
                      showList={listItem === 1 ? true : false}
                      clickHandler={() =>listItem !==1 ?  setItem(1): setItem(0)}
                    >
                      data
                    </AccData>
                    <AccData
                      header="Section 2"
                      showList={listItem === 2 ? true : false}
                      clickHandler={() => listItem !==2 ?  setItem(2): setItem(0)}
                    >
                      {[1, 2, 3, 4].map((item) => {
                        return (
                          <div className="acc-list-item" key={item}>
                            <div className="list-icon">
                              <UpArrow color={"#48A0F3"} />
                            </div>
                            {item}
                          </div>
                        );
                      })}
                    </AccData>
                    <AccData
                      header="Section 3"
                      showList={listItem === 3 ? true : false}
                      clickHandler={() => listItem !==3 ?  setItem(3): setItem(0)}
                    >
                      data
                    </AccData>
                    {/* <AccData/> */}
                    {/* <AccData/> */}
                  </div>
                </Col>
                <Col lg="6">
                  <Card className="bg-primary-100">
                    <h4 className="p-4 pb-0 fw-700">HTML Code:</h4>
                    <hr className="mb-0" />
                    <CardBody>
                      <span>
                        <span>&lt;</span>div <span>&gt;</span>
                      </span>
                      <br />
                      <span>
                        <span>&lt;</span>div class="accordion"<span>&gt;</span>
                      </span>
                      <br />
                      <span>
                        <span>&lt;</span>div class="accordion-item"
                        <span>&gt;</span>
                      </span>
                      <br />
                      <span>
                        <span>&lt;</span>div class="accordion-title
                        accordion-Inactive"<span>&gt;</span>
                      </span>
                      <br />
                      <span>
                        <span>&lt;</span>div <span>&gt;</span>
                      </span>
                      <br />
                      Section 1
                      <br />
                      <span>
                        <span>&lt;</span>/div<span>&gt;</span>
                      </span>
                      <br />
                      <span>
                        <span>&lt;</span>div class="upIcon"<span>&gt;</span>
                      </span>
                      <br />
                      <span>
                        <span>&lt;</span>svg <span>&gt;</span>
                      </span>
                      <br />
                      <span>
                        <span>&lt;</span>/svg<span>&gt;</span>
                      </span>
                      <br />
                      <span>
                        <span>&lt;</span>/div<span>&gt;</span>
                      </span>
                      <br />
                      <span>
                        <span>&lt;</span>/div<span>&gt;</span>
                      </span>
                      <br />
                      <span>
                        <span>&lt;</span>/div<span>&gt;</span>
                      </span>
                      <br />
                      <span>
                        <span>&lt;</span>/div<span>&gt;</span>
                      </span>
                      <br />
                      <span>
                        <span>&lt;</span>div class="accordion"<span>&gt;</span>
                      </span>
                      <br />
                      <span>
                        <span>&lt;</span>div class="accordion-item"
                        <span>&gt;</span>
                      </span>
                      <br />
                      <span>
                        <span>&lt;</span>div class="accordion-title
                        accordion-Inactive"<span>&gt;</span>
                      </span>
                      <br />
                      <span>
                        <span>&lt;</span>div <span>&gt;</span>
                      </span>
                      <br />
                      Section 2
                      <br />
                      <span>
                        <span>&lt;</span>/div<span>&gt;</span>
                      </span>
                      <br />
                      <span>
                        <span>&lt;</span>div class="upIcon"<span>&gt;</span>
                      </span>
                      <br />
                      <span>
                        <span>&lt;</span>svg <span>&gt;</span>
                      </span>
                      <br />
                      <span>
                        <span>&lt;</span>/svg<span>&gt;</span>
                      </span>
                      <br />
                      <span>
                        <span>&lt;</span>/div<span>&gt;</span>
                      </span>
                      <br />
                      <span>
                        <span>&lt;</span>/div<span>&gt;</span>
                      </span>
                      <br />
                      <span>
                        <span>&lt;</span>/div<span>&gt;</span>
                      </span>
                      <br />
                      <span>
                        <span>&lt;</span>/div<span>&gt;</span>
                      </span>
                      <br />
                      <span>
                        <span>&lt;</span>div class="accordion"<span>&gt;</span>
                      </span>
                      <br />
                      <span>
                        <span>&lt;</span>div class="accordion-item"
                        <span>&gt;</span>
                      </span>
                      <br />
                      <span>
                        <span>&lt;</span>div class="accordion-title
                        accordion-Inactive"<span>&gt;</span>
                      </span>
                      <br />
                      <span>
                        <span>&lt;</span>div <span>&gt;</span>
                      </span>
                      <br />
                      Section 3
                      <br />
                      <span>
                        <span>&lt;</span>/div<span>&gt;</span>
                      </span>
                      <br />
                      <span>
                        <span>&lt;</span>div class="upIcon"<span>&gt;</span>
                      </span>
                      <br />
                      <span>
                        <span>&lt;</span>svg <span>&gt;</span>
                      </span>
                      <br />
                      <span>
                        <span>&lt;</span>/svg<span>&gt;</span>
                      </span>
                      <br />
                      <span>
                        <span>&lt;</span>/div<span>&gt;</span>
                      </span>
                      <br />
                      <span>
                        <span>&lt;</span>/div<span>&gt;</span>
                      </span>
                      <br />
                      <span>
                        <span>&lt;</span>/div<span>&gt;</span>
                      </span>
                      <br />
                      <span>
                        <span>&lt;</span>/div<span>&gt;</span>
                      </span>
                      <br />
                      <span>
                        <span>&lt;</span>/div<span>&gt;</span>
                      </span>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </CardBody>
          </Container>
        </Card>
      </div>
      <CustomFooter />
    </div>
  );
};

export default PatternLibrary;
