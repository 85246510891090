import React from "react";
const FbBox = (props) => {
  return (
    <div className={props.name}>
      <svg
        width="7"
        height="13"
        viewBox="0 0 7 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7 4.20946H4.61605V2.86022C4.61605 2.35351 5.00521 2.23538 5.27931 2.23538C5.55279 2.23538 6.96164 2.23538 6.96164 2.23538V0.00780372L4.64473 0C2.07275 0 1.48744 1.6614 1.48744 2.72459V4.20946H0V6.50484H1.48744C1.48744 9.45063 1.48744 13 1.48744 13H4.61605C4.61605 13 4.61605 9.41565 4.61605 6.50484H6.72715L7 4.20946Z"
          fill="white"
        />
      </svg>
    </div>
  );
};
export default FbBox;
