export const vendors = [
  { img: "uploads/2020/01/login-story-2.png" },
  { img: "uploads/2020/01/login-story-4.png" },
  { img: "uploads/2020/01/login-story-1.jpg" },
  { img: "uploads/2020/01/login-story-6.png" },
  { img: "uploads/2020/01/login-story-9.png" },
  { img: "uploads/2020/01/login-story-11.png" },
  { img: "uploads/2020/01/login-story-1.png" },
  { img: "uploads/2020/01/login-story-14.png" },
  { img: "uploads/2020/01/login-story-3.png" },
  { img: "uploads/2020/01/login-story-5.png" },
  { img: "uploads/2020/01/login-story-7.png" },
  { img: "uploads/2020/01/login-story-10.png" },
  { img: "uploads/2020/01/login-story-13.png" },
  { img: "uploads/2020/01/login-story-12.png" },
];
