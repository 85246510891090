import { useState, useEffect, useContext } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Form } from "react-bootstrap";
import { Markup } from 'interweave';

// Scss
import "./styles.scss";
// Pages
import Banner from "../../../assets/images/loginBanner/login_bg.webp";
import Button from "../../../components/Form/Button";
import Input from "../../../components/Form/LoginInput";
import CircleLogo from "../../../assets/icons/logo_circle.svg";
import {
  postMethod,
  isLoading,
  isNotLoading,
} from "../../../services/api";
import Forgot from "./forgot";
import { vendors } from "../../../constants/loginData";
import { ResetPwd } from "../login/reset";
import Customheader from "../../../layouts/header/Customheader";
import Loader from '../../../components/loader'
import { IcContext } from '../../../hooks';

const LoginPage = () => {
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const email = query.get("login");
  const key = query.get("key");
  const Location = useLocation().pathname.slice(1);


  const Images = useContext(IcContext)
  // const [selectPage, setSelectPage] = useState(Location === "reset-password" ? "Reset" : Location === "forgot-password" ? "Forgot" : "Login");
  const [selectPage, setSelectPage] = useState(key ? "Reset" :  "Login");
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [btnLoading, setBtnLoading] = useState(false);
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [bannerImg, setBannerImg] = useState()
  const [state, setState] = useState([
    {
      email: "",
      msg: "",
      password: "",
      cPassword: "",
      user_id: "",
    },
  ]);
  useEffect(() => {
    ImgBanner()
  }, [])
  const ImgBanner = () => {
    setBannerImg(Images.bkdImg)
  }
  const fillImg = bannerImg ? bannerImg : Banner
  const bannerImage = {
    backgroundImage: "url(" + fillImg + ")",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundAttachment: "fixed",
  };

  const funnelRedirect = () => {
    window.open("https://lightersideofrealestate.com/")
  }
  //  login Page Api call //

  const errorCheck = (userName, password) => {
    let errorObj = [];

    if (userName === "" && password === "") {
      errorObj.push("Please enter your username and password");
    } else if (userName === "") {
      errorObj.push("Please enter your username");
    } else if (password === "") {
      errorObj.push("Please enter your password");
    }
    return errorObj;
  };

  const handleSelect = (e) => {
    setUserName("");
    setPassword("");
    setErrorMessage([]);
    e.stopPropagation();
    // navigate('/forgot-password')
    setSelectPage("Forgot");
  };

  const forgotRedirect = (e) => {
    e.stopPropagation();
    navigate('/')
    setSelectPage("Login");
    setErrorMessage([]);
  };

  //reset

  useEffect(() => {
    // if (Location === "reset-password" && key) {
    //   verifyToken();
    //   setSelectPage('Reset')
    // }
    // else if (Location === "forgot-password") {
    //   setSelectPage('Forgot')
    // }
    if(key){
      verifyToken();
      setSelectPage('Reset')
    }
    else {
      setSelectPage('Login')
    }
  }, []);


  const verifyToken = async () => {
    const obj = {
      url: "api_functions.php",
      body: {
        action: "reset_auth_valid",
        key: key,
        user_login: email,
      },
    };
    let { data } = await postMethod(obj);
    isNotLoading();
    setState({ ...state, user_id: data?.userid });
    if (data.status === 0) {
      setSelectPage("Login");
      navigate("/");
      setErrorMessage(data?.msg)
    }
    if (data.status === 1) {
      setSelectPage("Reset");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const checkError = errorCheck(userName, password);
    if (checkError.length === 0) {
      const data = {
        url: "api_functions.php",
        body: {
          username: userName,
          password: password,
          action: "login_request",
          remember: true,
        },
      };

      handleSubmitform(data);
      e.target.reset();
    } else {
      setErrorMessage(checkError);
    }
  };

  const handleSubmitform = async (obj) => {
    setBtnLoading(true);
    let { data } = await postMethod(obj);
    if (data.status === 1) {
      // setBtnLoading(false);
      // Cookies.set('login_ic_auth', newData.data.user_token);
      // navigate("/dashboard");
      window.location.href = data.redirect_url ? data.redirect_url : process.env.REACT_APP_DOMAINURL + '/dashboard'
      isLoading();
      setBtnLoading(false);
    } else
      if (data.status === 0 && data.status_code === "401") {
        setBtnLoading(true);
        window.location.href = data.redirect_url ? data.redirect_url : process.env.REACT_APP_DOMAINURL + '/dashboard'
        setErrorMessage(data.msg);
        // isLoading();
      } else {
        setBtnLoading(false);
        setErrorMessage(data.msg);
        setUserName("")
        setPassword("")
      }
  };
  return (
    <>
      <div id='pageLoader'><Loader /></div>
      <div className="loginHeader">
        <Customheader />
      </div>
      <div className="IC-home" style={bannerImage}>
        <div className="IC-Outter">
          <div className="Ic_banner">
            <Container className="content-outer">
              <Row>
                <Col md={{ order: 1, size: 8 }} xs={{ order: 2, size: 12 }}>
                  <div className="d-flex justify-content-center response_inner">
                    <div>
                      <p className="text-white mt-4 mb-4">
                        What is the Lighter Side’s Inner Circle?
                      </p>
                      <h1 className="text-white fw-700 text-start main-content">
                        Personalized social media content that keeps agents top
                        of mind.
                      </h1>
                      <div className="login-more">
                        <Button
                          name="Learn More"
                          type="submit"
                          className="btn-notice-500 login-more"
                          onClick={funnelRedirect}
                        />
                      </div>
                    </div>
                  </div>
                </Col>
                <Col
                  md={{ order: 2, size: 4 }}
                  xs={{ order: 1, size: 12 }}
                  className="align-self-center res-center"
                >
                  <Card className="login_Card br-5">
                    <div className="Ic-logo">
                      <img src={Images.icLogo ? Images.icLogo : CircleLogo} alt="" />
                    </div>
                    <CardBody className="Card-Content">
                      {selectPage === "Login" && <h5 className="Bodylarge fw-700">Member sign in</h5>}

                      {selectPage === "Forgot" && (
                        <>
                          <h5 className="Bodylarge fw-700">Forgot password?</h5>
                          <p className="Bodyreduce">
                            Please enter the email associated with your account
                            and we will email you a link to reset your password.
                          </p>
                        </>
                      )}
                      {selectPage === "Reset" && (
                        <>
                          <h5 className="Bodylarge fw-700">Enter your new password below:</h5>
                          <div className="mb-2">
                            <h6 className="Bodyreduce fw-700">Requirements:</h6>
                            <p className="mb-0 f-12">
                              Must be at least 8 characters.
                            </p>
                            <p className="f-12">
                              Cannot contain the following characters : ‘ “ /
                            </p>
                          </div>
                        </>
                      )}

                      {selectPage === "Login" && (
                        <Form
                          className="login-form"
                          onSubmit={handleSubmit}
                          noValidate
                          validated={validated}
                          autoComplete="off"
                        >
                          <Row>
                            <Col lg="12">
                              <Input
                                required
                                type="email"
                                name="email"
                                size="lg"
                                placeholder="Username"
                                className="mb-0"
                                onChange={(e) => {
                                  setUserName(e.target.value);
                                  setErrorMessage([]);
                                }}
                                value={userName}
                                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="12">
                              <Input
                                required
                                type="password"
                                name="password"
                                size="lg mb-0"
                                placeholder="Password"
                                onChange={(e) => {
                                  setPassword(e.target.value);
                                  setErrorMessage([]);
                                }}
                                value={password}
                                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="12" className="frgt-link">
                              <p className="f-12 text-primary-500 mb-0 mt-1 position-absolute pwd-info">
                                (Passwords are case-sensitive)
                              </p>
                              <a
                                className="reset-link-txt text-action-500 f-12 fw-400"
                                style={{ textDecoration: "none" }}
                                type="button"
                                onClick={handleSelect}
                              >
                                Forgot Password?
                              </a>
                            </Col>
                          </Row>
                          <Row>
                            {errorMessage?.length !== 0 && errorMessage !== undefined &&
                              <Col lg="12" className="text-center error-disp mb-2 mt-2">
                                <span className="f-14 lh-22 fw-700 text-attention-500">
                                  {/* <Markup content={errorMessage} /> */}
                                  {errorMessage}
                                </span>
                              </Col>
                            }
                          </Row>
                          <div className={`text-center ${errorMessage?.length !== 0 && errorMessage !== undefined ? "mt-1" : "mt-3"}`}>
                            <Button
                              name="Sign In"
                              type="submit"
                              loading={btnLoading}
                              className={`${btnLoading ? "btn-white" : "btn-action-500"} text-white login-btn`}
                              nameStyle="f-16 lh-24 fw-400"
                            />
                          </div>
                          <Row>
                            <Col lg="12" className="text-center">
                              <div className="checkbox mt-2 f-12">
                                <Form.Check
                                  type={"checkbox"}
                                  label={`Remember me`}
                                  defaultChecked
                                  className="checkbox text-primary-700 remember"
                                />
                              </div>
                            </Col>
                          </Row>
                        </Form>
                      )}
                      {selectPage === "Forgot" && (
                        <Forgot forgotRedirect={forgotRedirect} />
                      )}
                      {selectPage === "Reset" && (
                        <ResetPwd
                          user_id={state.user_id}
                          selectPage={selectPage}
                          setSelectPage={setSelectPage}
                        />
                      )}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
      {/* image listing  start*/}
      <div className="bg-white cmd-list">
        <Container>
          <div className="Ic-image-content">
            <h2 className="text-center f-36 lh-38 fw-700">
              Wouldn’t you <span className="fs-i">love</span> your sphere to
              say things like this about you?
            </h2>
            <p className="text-center mt-2 members-font">
              <b className=" f-20 lh-26">
                Our members get love like this all the time.{" "}
                <img
                  alt=''
                  className="emoji"
                  src="https://s.w.org/images/core/emoji/13.0.0/svg/1f642.svg"
                />
              </b>
            </p>
            <div className="card-columns text-center">
              {vendors.map((item, i) => (
                <img
                  className="boxshadowed"
                  src={process.env.REACT_APP_CDNURL + item.img}
                  key={i}
                  alt=''
                />
              ))}
            </div>
          </div>
        </Container>
      </div>

      {/* image listing  end*/}

      <div className="content-middle bg-action-500">
        <Container>
          <Row>
            <Col lg="8" sm='8' className="p-0">
              <p className="text-white mb-0">
                We didn’t invent top-of-mind marketing for real estate agents.
                We perfected it.
              </p>
            </Col>
            <Col lg="4" sm='4' className="align-self-center content-middle-button">
              <div className="login-more text-center">
                <Button
                  name="Learn More"
                  type="submit"
                  className="btn-notice-500 "
                  onClick={funnelRedirect}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className=" login-footer d-flex justify-content-center bg-primary-800">
        <div className="footer-copyright align-self-center justify-content-between">
          <div className="copyright">
            <img
              src={process.env.REACT_APP_CDNURL + "uploads/2015/12/innercircle-logo-lt-small.png"}
              alt="IC_footerLogo"
              width={106}
              className="IC_footerLogo me-3"
            />
            <span className="f-12 text-white copy-text">
              {" "}
              © 2022 Lighter Side of Real Estate
              <a className="ms-2 text-white copy-text" href={process.env.REACT_APP_DOMAINURL + '/privacy-policy'}>
                Privacy{" "}
              </a>
              -{" "}
              <a className=" text-white copy-text" href={process.env.REACT_APP_DOMAINURL + '/terms'}>
                Terms
              </a>
            </span>
          </div>
        </div>
        <div className="mobile-view text-center p-2">
          <img
            src={process.env.REACT_APP_CDNURL + "uploads/2015/12/innercircle-logo-lt-small.png"}
            alt="IC_footerLogo"
            width={70}
            className="IC_footerLogo mb-2 me-3"
          />
          <p className="f-12 text-white copy-text">
            {" "}
            © 2022 Lighter Side of Real Estate
            <a className="ms-2 text-white copy-text" href={process.env.REACT_APP_DOMAINURL + '/privacy-policy'}>
              Privacy{" "}
            </a>
            -{" "}
            <a className=" text-white copy-text" href={process.env.REACT_APP_DOMAINURL + '/terms'}>
              Terms
            </a>
          </p>
        </div>
      </div>
    </>
  );
};

export default LoginPage;
