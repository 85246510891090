const FbWhite = (props) => {
  return (
    <div className={props.name}>
      <svg
        width="10"
        height="22"
        viewBox="0 0 10 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 7.1237H6.59435V4.84036C6.59435 3.98286 7.1503 3.78294 7.54187 3.78294C7.93255 3.78294 9.94521 3.78294 9.94521 3.78294V0.0132063L6.63533 0C2.96106 0 2.12491 2.81159 2.12491 4.61085V7.1237H0V11.0082H2.12491C2.12491 15.9934 2.12491 22 2.12491 22H6.59435C6.59435 22 6.59435 15.9342 6.59435 11.0082H9.61021L10 7.1237Z"
          fill="#3A559C"
        />
      </svg>
    </div>
  );
};
export default FbWhite;
