const TwitterWhite = (props) => {
  return (
        <div className={props.name}>

    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 2.13074C19.2648 2.49268 18.4735 2.73682 17.6433 2.84608C18.491 2.28406 19.1409 1.39382 19.4475 0.331818C18.6546 0.852925 17.7768 1.23105 16.8418 1.43518C16.0936 0.55213 15.0271 0 13.8469 0C11.5811 0 9.74403 2.03452 9.74403 4.54429C9.74403 4.89994 9.78016 5.24659 9.85039 5.57976C6.44013 5.39002 3.41636 3.58121 1.39253 0.831793C1.03932 1.50262 0.83714 2.28316 0.83714 3.11675C0.83714 4.69311 1.56142 6.08423 2.66244 6.89894C1.99013 6.87511 1.35721 6.67053 0.803849 6.33017C0.803443 6.34905 0.803443 6.36839 0.803443 6.38772C0.803443 8.58905 2.21789 10.4253 4.09516 10.8434C3.75089 10.9468 3.38834 11.0026 3.01403 11.0026C2.74933 11.0026 2.49234 10.9743 2.24184 10.9208C2.76435 12.726 4.27948 14.0402 6.07474 14.0766C4.67044 15.2955 2.90157 16.0221 0.978828 16.0221C0.647951 16.0221 0.321134 16.0005 0 15.9583C1.81637 17.2482 3.97296 18 6.28991 18C13.8376 18 17.9644 11.0759 17.9644 5.07079C17.9644 4.87386 17.9607 4.67737 17.953 4.48224C18.7544 3.84288 19.4503 3.04211 20 2.13074Z"
        fill="#2CACE2"
      />
    </svg>
    </div>
  );
};
export default TwitterWhite;
