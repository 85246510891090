import React from "react";
import IconUp from "../social icons/IconUp";
import "./styles.scss";
const AccData = (props) => {
  return (
    <React.Fragment>
      <div className="accordion">
        <div className="accordion-item">
          <div
            className={`accordion-title ${
              props.showList ? "accordion-active" : "accordion-Inactive"
            }`}
            onClick={props.clickHandler}
          >
            <div>{props.header}</div>
            <div className={`${props.showList ? "downIcon" : "upIcon"}`}>
              <IconUp />
            </div>
          </div>
          {props.showList && (
            <div className="accordion-content">{props.children}</div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default AccData;
