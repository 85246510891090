import { Form } from "react-bootstrap";
import "./style.scss";
const Input = ({
  label,
  type,
  name,
  value,
  onChange,
  placeholder,
  size,
  errorMessage,
  isInvalid,
  min,
  max,
  disabled,
  addText,
  onBlur,
  autoComplete,
  pattern
}) => {
  return (
    <Form.Group className={"form-groupl " + size} controlId={name}>
      {/* <div className="d-grid">
      <Form.Label id={name} className="fw-700 f-14 text-black">{label}</Form.Label>
      {addText ? <label id={addText} className="fw-400 f-14 text-black mb-1">{addText}</label> : ""}
      </div> */}
      <Form.Control
        required
        type={type}
        name={name}
        value={value}
        isInvalid={isInvalid}
        placeholder={placeholder}
        onChange={onChange}
        min={min}
        disabled={disabled}
        onBlur={onBlur}
        pattern={pattern}
        autoComplete={autoComplete}
      />
      {/* tooltip={true} style={{right:"0"}} */}
      {/* <Form.Control.Feedback tooltip={false} style={{right:"0"}} type="invalid"> 
      <p><i className="fa fa-times-circle" aria-hidden="true"></i> {errorMessage}</p>
      </Form.Control.Feedback> */}
    </Form.Group>
  );
};

export default Input;
