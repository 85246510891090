import React, { useState } from "react";
import { Row, Col } from "reactstrap";
import { Form } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
//Pages
import Input from "../../../components/Form/Input";
import Button from "../../../components/Form/Button";
import { postMethod } from "../../../services/api";
import './styles.scss'

const ResetPwd = (props) => {
  const [validated, setValidated] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const navigate = useNavigate();
  const [state, setState] = useState({
    password: "",
    cPassword: "",
    msg: "",
  });
  const [errColor, setErrColor] = useState(false);
  const handleInput = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let passwordMatch =
      event.target.password.value === event.target.cPassword.value
        ? true
        : false;
    if (state.password === "") {
      setState({ ...state, msg: "Please enter your new password" });
    } else if (state.password.length && state.cPassword.length < 8) {
      setState({ ...state, msg: "Enter a minimum of 8 characters" });
    } else if (state.password !== "" && passwordMatch === true) {
      const reg = /^[A-Za-z0-9#@!#$^&*?%+{`~:().,;|}_=\s]*$/;
      // const reg= /^[/'"\\]*$/
      if (reg.test(state.cPassword)) {
        let space = /^[A-Za-z0-9#@!#$^&*?%+{`~:().,;|}_=]*$/
        console.log(space.test(state.cPassword))
        if (space.test(state.cPassword)) {
          setState({ ...state, msg: "" })
          setNewPassword();
        } else {
          setState({ ...state, msg: "Enter a minimum of 8 characters" })
        }
      }
      else {
        setState({ ...state, msg: "Passwords cannot contain: ‘ “ /" });
      }
    } else {
      setState({ ...state, msg: "Password doesn't match" });
    }
  };
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const email = query.get("login");
  const key = query.get("key");

  const setNewPassword = async () => {
    setBtnLoading(true);
    const obj = {
      url: "api_functions.php",
      body: {
        action: "reset_password_request",
        password: state.cPassword,
        key: key,
        user_login: email,
        user_id: props.user_id,
      },
    };
    let { data } = await postMethod(obj);
    if (data.status === 1) {
      setState({ ...state, msg: data.msg });
      setErrColor(true)
      setBtnLoading(false);
      setTimeout(() => {
        navigate("/");
        props.setSelectPage("Login");
      }, 2000);

    }
    else if (data.status === 0) {
      setState({ ...state, msg: data.msg });
      setErrColor(false)
      setBtnLoading(false);
      setTimeout(() => {
        navigate("/");
        props.setSelectPage("Login");
      }, 2000);

    }
  };
  return (
    <>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row>
          <Col lg="12">
            <Input
              required
              type="password"
              name="password"
              size="lg"
              placeholder="New password"
              onChange={handleInput}
              pattern="^((?=.*[#?!@$%^&*-]){1})(?=.*\d)((?=.*[a-z]){1})[^\s]((?=.*[A-Z]){1}).*$"
              min={8}
            />
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <Input
              required
              type="password"
              name="cPassword"
              size="lg"
              // disabled={state.password.length > 8 ? false : true}
              placeholder="Confirm password"
              onChange={handleInput}
            />
          </Col>
        </Row>
        <Row>
          {state.msg ?
            <Col lg="12" className="text-center error-disp mt-3 mb-2">
              <span
                className={`f-14 lh-22 fw-700 ${errColor ? "text-success-500" : "text-attention-500"
                  }`}
              >
                {state.msg}
              </span>
            </Col>
            : ""}
        </Row>
        <div className={`text-center mb-2 ${state.msg ? "mt-2" : "mt-4"}`}>
          <Button
            name="Reset Password"
            type="submit"
            disabled={btnLoading}
            loading={btnLoading}
            className={`${btnLoading ? "btn-white" : "btn-action-500"} bar-radius text-white reset`}
            nameStyle="f-16 lh-24 fw-400"
          />
        </div>
      </Form>
    </>
  );
};
export { ResetPwd };
